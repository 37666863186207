import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import Input from "../../UI/Input/Input.jsx";
import Select from "../../UI/Select/Select";
import { useState, useEffect } from "react";
import AutoHeightTextarea from "../Textarea/AutoHeightTextarea.jsx";

import { ReactComponent as ChangeBlock } from "../../images/profile/change_block.svg";
import { ReactComponent as DeleteBlock } from "../../images/profile/delete_block.svg";
import { ReactComponent as SaveChanges } from "../../images/profile/save_changes.svg";

import Modals from "../../components/Modals/Modals/Modals";
import { getValue } from "@testing-library/user-event/dist/utils/index.js";

export default function ChangeInfo({
    educations,
    mode = "default",
    startDate = null,
    endDate = null,
    organisationName = "",
    organisationType = "",
    position = "",
    region = "",
    organ_msu,
    workType = "",
    isHead = false,
    file,
    name_activity,
    type_org,
    onDelete,
    status,
    comment,
    onEdit,
    onStateChange,
}
) {

    const [education, setEducation] = useState(educations);
    const [modalStatus, setModalStatus] = useState("none");
    const [modalStatusEducationEdit, setModalStatusEditEducation] = useState("none");
    const [modalStatusWork, setModalStatusWork] = useState("none");
    const [modalStatusMSU, setModalStatusMSU] = useState("none");
    const [modalStatusInternship, setModalStatusInternship] = useState("none");
    const [statusCom, setStatus] = useState("Ожидает проверки");
    const [statusStyle, setStatusStyle] = useState("user__additionaInfo-content__status_pending");
    const [editBtn, setEditBtnStatus] = useState("visible");
    const [deleteBtn, setDeleteBtnStatus] = useState("visible");

    // const removeItem = (index) => {
    //     const newEducation = education.filter((_, i) => i !== index);
    //     setEducation(newEducation);
    // };

    const date = new Date().getFullYear();
    const customDateOfBirthValidation = (value) => {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            // return Number(date - 14) > Number(newValue.slice(6, 10));
            return newValue;
        }
        return false;
    };

    const handleClick = () => {
        setModalStatus("add_education");
        // console.log(modalStatus);
    };
    // console.log(modalStatus);    

    const manageStatus = (status) => {
        console.log(status)
        switch (status) {
            case 'NEW':
                setStatus('Ожидает проверки');
                setEditBtnStatus("visible");
                setDeleteBtnStatus("visible");
                setStatusStyle ('user__additionaInfo-content__status_pending');
                break 
            case 'Verified':
                setStatus('Проверка пройдена');
                setEditBtnStatus("not_visible");
                setDeleteBtnStatus("not_visible");
                setStatusStyle ('user__additionaInfo-content__status_success');
                break 
            case 'Rejected':
                setStatus('Отклонено модератором');
                setEditBtnStatus("not_visible");
                setDeleteBtnStatus("visible");
                setStatusStyle ('user__additionaInfo-content__status_denied');
                break 
            case 'WAIT':
                setStatus('Ожидает изменения');
                setEditBtnStatus("visible");
                setDeleteBtnStatus("visible");
                setStatusStyle ('user__additionaInfo-content__status_change');
                break 
            default:
                console.log(`status error`);
                setStatus("Неизвестен");
                setEditBtnStatus("visible");
                setDeleteBtnStatus("visible");
                setStatusStyle('');
        }
    }

    useEffect(() => {
        console.log(status)
        manageStatus(status);
    }, [status]); 

    return (
        <>
            <div className="user__additionaInfo__block">
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">

                        {mode === "msu" && (
                            <div class="user__additionaInfo__line-wrapper">
                                <div className={`user__additionaInfo-content__status ${statusStyle}`}>Статус: <span>{statusCom}</span></div>
                                <div className={`user__additionaInfo-content__status_comment`}>{comment}</div>
                            </div>
                        )}

                        {mode === "msu" && (
                            <div className="user__participations__linewrapper">
                                <MaskedCustomInput
                                    type="text"
                                    required={true}
                                    id="region"
                                    value={region}
                                    disabled={true}
                                />
                                <MaskedCustomInput
                                    type="text"
                                    required={true}
                                    id="organ_msu"
                                    value={organ_msu}
                                    disabled={true}
                                />
                                <a className="user__participations__document_link" href={file} target="_blank">
                                    {file !== "" ? "Документ" : "Документ отсутствует"}
                                </a>
                            </div>
                        )}

                        {mode === "internships" && (
                            <div class="user__additionaInfo__line-wrapper">
                                <div className={`user__additionaInfo-content__status ${statusStyle}`}>Статус: <span>{statusCom}</span></div>
                                <div className={`user__additionaInfo-content__status_comment`}>{comment}</div>
                            </div>)}

                        {mode === "internships" && (
                            <div className="user__participations__linewrapper">
                                <MaskedCustomInput
                                    type="text"
                                    required={true}
                                    id="name_activity"
                                    value={name_activity !== "" ? name_activity : "Форма"}
                                />
                                <MaskedCustomInput
                                    type="text"
                                    required={true}
                                    id="type_org"
                                    value={type_org !== "" ? type_org : "подведомственные учреждения органов государственной власти субъекта РФ"}
                                />

                                <a className="user__participations__document_link" href={file} target="_blank">
                                    {file !== "" ? "Документ" : "Документ отсутствует"}
                                </a>
                            </div>
                        )}



                        <div className="user__additionaInfo__line-wrapper">
                            <div className="user__additionaInfo-date-container">
                                <div className="user__additionaInfo-date-name">Год</div>

                                <div className="user__additionaInfo-date-wrapper">
                                    <div className="user__additionaInfo-date">
                                        <MaskedCustomInput
                                            text="От"
                                            type="text"
                                            required={true}
                                            id="start_date"
                                            value={customDateOfBirthValidation(startDate)}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                                    <div className="user__additionaInfo-date">
                                        <MaskedCustomInput
                                            text="До"
                                            type="text"
                                            value={endDate == "9999-12-31"? "по н.в.": customDateOfBirthValidation(endDate)}
                                            required={true}
                                            id="end_date"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                                {/* <Input
                                    id={mode + "_organisation_name"}
                                    required={true}
                                    type="text"
                                    text="Наименование организации"
                                    value={organisationName}
                                /> */}
                                <AutoHeightTextarea
                                    id={mode + "_organisation_name"}
                                    value={organisationName}
                                    // placeholder={organisationName}
                                    title="Наименование организации"
                                />
                            </div>
                            {mode !== "education" && (
                                <div className="user__additionaInfo-text user__additionaInfo-text-position">
                                    {/* <Input
                                        id={mode + "_position"}
                                        required={true}
                                        type="text"
                                        text="Должность"
                                        value={position}
                                    /> */}
                                    <AutoHeightTextarea
                                        id={mode + "_position"}
                                        value={position}
                                        title="Должность"
                                    />
                                </div>
                            )}

                            {mode === "education" && (
                                <div className="user__additionaInfo-text user__additionaInfo-text-position">
                                    {/* <Input
                                        id={mode + "_position"}
                                        required={true}
                                        type="text"
                                        text="Специальность"
                                        value={position}
                                    /> */}

                                    <AutoHeightTextarea
                                        id={mode + "_position"}
                                        value={position}
                                        title="Специальность"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {mode === "internships" && (
                        <div className="user__participations__section__organisationType" id={mode + "_isHead"}>{isHead}</div>
                    )}
                </div>
                <div className="user__additionaInfo__buttons">
                    <button type="button" className={editBtn}
                        onClick={() => {
                            onEdit()
                            onStateChange()
                        }}
                    ><ChangeBlock /></button>
                    <button type="button" className={deleteBtn} onClick={onDelete}><DeleteBlock /></button>
                </div>
            </div>
        </>
    );
}
