// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DAS-error-box {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FF0000;
}`, "",{"version":3,"sources":["webpack://./src/UI/ErrorBox/ErrorBox.scss","webpack://./src/styles/variables.scss","webpack://./src/UI/ErrorBox/ErrorBox.css"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCOS;ACRX","sourcesContent":["@import \"../../styles/variables.scss\";\n\n.DAS-error-box {\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 20px;\n  color: $dark-red;\n}\n","$accent-color: #141652;\r\n$light-main: #F8F8F8;\r\n$background-opaced: #F8F8F8B2;\r\n$text-opaced: #141652B2;\r\n$int-red: #9C0B26;\r\n$dark-green: #00B65E;\r\n$orange: #FF7A00;\r\n\r\n\r\n$text-white: #FFFFFF;\r\n$text-black: #000000;\r\n$text-purple: #2C2C59;\r\n$text-whiteblue: #EEF4FF;\r\n$int-black: #202225;\r\n$int-white: #FFFFFF;\r\n$int-green: #66C676;\r\n$int-purple: #A9AAC2;\r\n$int-blue: #1976d2;\r\n$light-blue: #0082E0;\r\n$int-image: #EEF4FF;\r\n$dark-red: #FF0000;\r\n$light-green: #219653;\r\n$dark-blue: #4449BE;\r\n$turquoise: #4AA6BA;\r\n$dark-purple: #1a254e;\r\n$int-pink: #eb5757;\r\n$int-gray: #e2e2e2;\r\n$int-gray-dark: #132c14;\r\n\r\n$container-max-width: 1550px;\r\n\r\n//Breakpoints\r\n$breakpoint-xxl: 1600px;\r\n$breakpoint-xl: 1335px;\r\n$breakpoint-lg: 1023px;\r\n$breakpoint-md: 719px;\r\n$breakpoint-sm: 413px;\r\n\r\n\r\n//Transitions speed\r\n$accordion-transition-speed: 0.5s;\r\n$tabs-transition-speed: 1s;\r\n$circle-dots-transition-speed: 1.2s;\r\n\r\n\r\n",".DAS-error-box {\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 20px;\n  color: #FF0000;\n}/*# sourceMappingURL=ErrorBox.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
