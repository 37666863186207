// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-event__table {
  border-radius: 7px;
  background-color: #fff;
  padding: 23px 0px 4px 0px;
  min-height: 522px;
}`, "",{"version":3,"sources":["webpack://./src/pages/StatisticsEvent/StatisticsEvent.scss","webpack://./src/pages/StatisticsEvent/StatisticsEvent.css"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,sBAAA;EACA,yBAAA;EACA,iBAAA;ACAJ","sourcesContent":[".statistics-event {\n  &__table {\n    border-radius: 7px;\n    background-color: #fff;\n    padding: 23px 0px 4px 0px;\n    min-height: 522px;\n  }\n}\n",".statistics-event__table {\n  border-radius: 7px;\n  background-color: #fff;\n  padding: 23px 0px 4px 0px;\n  min-height: 522px;\n}/*# sourceMappingURL=StatisticsEvent.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
