import useOnclickOutside from "react-cool-onclickoutside";
import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";

import { UserActions } from "../../../interface/userActions";
import { usersParams } from "../../../interface/usersParams";
import { Functions } from "../../../interface/functions";
import ConvertData from "../../../interface/ConvertData";

import no_image from "../../../images/other/no-photo.png";
import close_window from "../../../images/other/close_window.png";

import "./BookkepingModal.css";

export default function BookkepingModal({
    modalStatus,
    activeTable,
    isLoadingDetailUser,
    setModalStatus,
    detailUser,
    setDetailUser,
    paymentDatetime,
    updatePaymentDatetime,
    selectedUserId,
    typeOfLearning,
    setTransferDetailUser,
    changeButtonStatus,
    changeFetchStatus,
}) {
    const user_role = useSelector(({ profile }) => profile.role);

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const [buttonStatus, setButtonStatus] = useState("");

    const [dataInput, setDataInput] = useState("");
    const [isEditDataInput, setIsEditDataInput] = useState(false);

    const dateInput = useOnclickOutside(() => setIsEditDataInput(false));

    const modalTitle = useRef(null);
    const modalDescription = useRef(null);

    const setPaymentDay = () => {
        const paymentDate = detailUser.payment_datetime;
        if (paymentDate === null || paymentDate === undefined) {
            setDataInput("");
        } else {
            const newpaymentDate = paymentDate.slice(0, 10);
            setDataInput(newpaymentDate);
        }
    };

    const onChangeInputDatePayment = e => {
        setDataInput(e.target.value);
    };

    const setInput = () => {
        if (user_role === usersParams.role.admin) {
            setIsEditDataInput(true);
        }
    };

    const hendleClearDate = () => {
        setPaymentDay();
        setDetailUser(false);
    };

    const setTitleAndDescription = () => {
        switch (modalStatus) {
            case "ENTRY_users":
                modalTitle.current.innerHTML = "Вступление";
                modalDescription.current.innerHTML = "";
                break;
            case "user_PROL":
                modalTitle.current.innerHTML = "Продление";
                modalDescription.current.innerHTML = "";
                break;
            case "user_TRANS":
                modalTitle.current.innerHTML = "Передан в ЦБ";
                modalDescription.current.innerHTML = "";
                break;
            case "user_CONF":
                modalTitle.current.innerHTML = "Подтверждена уплата взносов";
                modalDescription.current.innerHTML = "";
                break;
            default:
                modalTitle.current.innerHTML = "default";
                modalDescription.current.innerHTML = "default";
                break;
        }
    };

    useEffect(() => {
        if (detailUser.image) {
            Functions.setUserPhoto(detailUser.image);
        }
    }, [detailUser.image]);

    const updateStatusUser = (text, buttonStatus) => {
        let selectedArr = [
            {
                id: selectedUserId,
                payment_status: buttonStatus,
            },
        ];
        if (dataInput === "" && typeOfLearning === "budget" && buttonStatus !== "TRANS") {
            let currentDateTime = new Date().toISOString();
            let newFormatDateTime = currentDateTime.slice(0, 10);
            let paymentDateTime = newFormatDateTime;
            const resp = UserActions.updateStatus(selectedArr, buttonStatus, paymentDateTime);
            if (resp && setTransferDetailUser) {
                updatePaymentDatetime(dataInput);
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else if (buttonStatus === "TRANS" && dataInput === "" && typeOfLearning === "budget") {
            let paymentDateTime = undefined;
            const resp = UserActions.updateStatus(selectedArr, buttonStatus, paymentDateTime);
            if (resp && setTransferDetailUser) {
                updatePaymentDatetime(dataInput);
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else {
            const resp = UserActions.updateStatus(selectedArr, buttonStatus, dataInput);
            if (resp && setTransferDetailUser) {
                updatePaymentDatetime(dataInput);
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        }
        setModalStatus("none");
    };

    const setInputPaymentDatetime = () => {
        if (!isEditDataInput) {
            return (
                <div onClick={setInput} className="input-wrapper_fake">
                    <Input mode="fake" text="Дата уплаты взносов" placeholder={dataInput} />
                </div>
            );
        } else {
            return (
                <div className="input-wrapper_fake" ref={dateInput}>
                    <Input
                        mode="datePayment"
                        id="payment_datetime"
                        type="date"
                        text="Дата уплаты взносов"
                        value={dataInput}
                        onChange={onChangeInputDatePayment}
                    />
                </div>
            );
        }
    };

    useEffect(() => {
        if (modalTitle.current && modalDescription.current) {
            setTitleAndDescription();
        }
    }, [modalTitle.current, modalDescription.current, modalStatus]);

    useEffect(() => {
        setPaymentDay();
    }, [detailUser.payment_datetime]);

    return (
        <>
            <div className="modals-wrapper">
                <div className="modal__container" style={{ display: modalStatus !== "none" ? "block" : "none" }}></div>
                <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                    <div className="modal-content">
                        <div
                            className="modal-close"
                            onClick={() => {
                                setModalStatus("none");
                                hendleClearDate();
                            }}
                        >
                            <img src={close_window} alt="Close" />
                        </div>
                        {isLoadingDetailUser ? (
                            <div className="modal-loader">Загрузка...</div>
                        ) : (
                            <form className="modalForms-mainEdit change-modal">
                                <div className="modalForms-header">
                                    <div className="modalForms-header-image">
                                        <PhotoInput
                                            mode="disable"
                                            user_photo={detailUser ? Functions.setUserPhoto(detailUser.image) : ""}
                                        />
                                    </div>
                                    <div className="modalForms-header-info">
                                        <div ref={modalTitle} className="modalForms-header-title"></div>
                                        <div ref={modalDescription} className="modalForms-header-description"></div>
                                    </div>
                                </div>
                                <Input
                                    mode="fake_translit"
                                    text="Фамилия"
                                    placeholderTranslit={detailUser ? detailUser.last_name_translated : ""}
                                    textTranslit="Транслит поля Фамилия"
                                    placeholder={detailUser ? detailUser.last_name : ""}
                                />
                                <Input
                                    mode="fake_translit"
                                    text="Имя"
                                    placeholderTranslit={detailUser ? detailUser.first_name_translated : ""}
                                    textTranslit="Транслит поля Имя"
                                    placeholder={detailUser ? detailUser.first_name : ""}
                                />
                                <Input
                                    mode="fake_translit"
                                    text="Отчество"
                                    placeholderTranslit={detailUser ? detailUser.middle_name_translated : ""}
                                    textTranslit="Транслит поля Отчество"
                                    placeholder={detailUser ? detailUser.middle_name : ""}
                                />
                                <Input
                                    mode="fake"
                                    text="Адрес электронной почты"
                                    placeholder={detailUser ? detailUser.email : ""}
                                />
                                <Input
                                    mode="fake"
                                    text="Номер телефона"
                                    placeholder={detailUser ? detailUser.phone_number : ""}
                                />
                                <Input
                                    mode="fake"
                                    text="Регион"
                                    placeholder={detailUser ? detailUser.faculty : ""}
                                />
                                <Input
                                    mode="fake"
                                    text="Уровень обучения"
                                    placeholder={
                                        detailUser
                                            ? ConvertData.getData(
                                                  "academic_level",
                                                  detailUser.academic_level,
                                                  faculties.filter(
                                                      item => Number(item.id) === Number(detailUser.faculty_id)
                                                  )[0]?.academic_level
                                              ) ?? ""
                                            : ""
                                    }
                                />
                                <Input
                                    mode="fake"
                                    text="Тип обучения"
                                    placeholder={
                                        detailUser
                                            ? ConvertData.getData(
                                                  "type_of_learning",
                                                  detailUser.type_of_learning,
                                                  faculties.filter(
                                                      item => Number(item.id) === Number(detailUser.faculty_id)
                                                  )[0]?.type_of_learning
                                              ) ?? ""
                                            : ""
                                    }
                                />

                                {activeTable !== "PASSED" && activeTable !== "FILLED" ? (
                                    setInputPaymentDatetime()
                                ) : (
                                    <></>
                                )}

                                <Input
                                    mode="fake"
                                    text="Текущий Курс"
                                    placeholder={detailUser ? detailUser.grade_level : ""}
                                />

                                <div className="input-wrapper_fake"></div>
                                {(activeTable === "ENTRY" || activeTable === "PROL") && typeOfLearning === "budget" && (
                                    <>
                                        <Button
                                            type="button"
                                            text="ПЕРЕДАТЬ В ЦБ"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "TRANS")}
                                        />
                                    </>
                                )}
                                {activeTable === "TRANS" && typeOfLearning === "budget" && (
                                    <>
                                        <Button
                                            type="button"
                                            text="ПОДТВЕРДИТЬ ОПЛАТУ"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "CONF")}
                                        />
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
