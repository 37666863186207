// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 100;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}
.overlay_active {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/Overlay/Overlay.scss","webpack://./src/components/Modals/Overlay/Overlay.css"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,uDAAA;ACDF;ADGE;EACE,mBAAA;EACA,UAAA;EACA,uDAAA;ACDJ","sourcesContent":["\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.50);\n  visibility: hidden;\n  z-index: 100;\n  opacity: 0;\n  transition: visibility 0.2s linear, opacity 0.2s linear;\n\n  &_active {\n    visibility: visible;\n    opacity: 1;\n    transition: visibility 0.2s linear, opacity 0.2s linear;\n  }\n\n  &__container {\n    // width: 100%;\n    // height: 100vh;\n    // display: flex;\n    // align-items: center;\n    // justify-content: center;\n  }\n}\n",".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  visibility: hidden;\n  z-index: 100;\n  opacity: 0;\n  transition: visibility 0.2s linear, opacity 0.2s linear;\n}\n.overlay_active {\n  visibility: visible;\n  opacity: 1;\n  transition: visibility 0.2s linear, opacity 0.2s linear;\n}/*# sourceMappingURL=Overlay.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
