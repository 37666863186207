import { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as Yup from "yup";

import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import InputTranslate from "../../UI/InputTranslate/InputTranslate";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import CropPhoto from "../../components/CropPhoto/CropPhoto";
import Modals from "../../components/Modals/Modals/Modals";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import Input from "../../UI/Input/Input";
import Radio from "../../UI/Radio/Radio";
import LogoGroup from "../../components/LogoGroup/LogoGroup";
import Footer from "../../components/Footer/Footer";

import { showRegistrationErrors, getIncludesValues } from "../../interface/information-module";
import { SetFieldsStatus } from "../../interface/SetFieldsStatus";
import { hideScroll } from "../../interface/useDisableBodyScroll";
import * as regExpList from "../../interface/RegExpDictionary";
import CustomValidationUser from "../../interface/Validation";
import { ImageLoader } from "../../interface/ImageLoader";
import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import { academic_level, COURCES_LIST } from "../../assets/academic_level";
import { academicLevelSlice } from "../../assets/academic_level_slice";
import { type_of_learning } from "../../assets/type_of_learning";

import mgu_logo from "../../images/other/reg_logo.png";
import { ReactComponent as Success } from "../../images/other/success_message.svg";

import "./CreateAccountPage.css";

export default function CreateAccountPage() {
    const userData = useSelector(({ profile }) => profile);

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите регион" },
        ...facultiesList.facultiesList,
    ]);

    const [modalStatus, setModalStatus] = useState(Cookies.get("modalCookie") === "false" ? "none" : "none");

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [croppedData, setCroppedData] = useState("");
    const [registred, setRegistred] = useState(false);
    const [croppedFile, setCroppedFile] = useState();
    const [inputImage, setInputImage] = useState();
    const [canCrop, setCanCrop] = useState(false);
    const [checked, setChecked] = useState(true);
    const [fetch, setFetch] = useState(false);

    const type_of_learning_ref = useRef(null);
    const academic_level_ref = useRef(null);
    const studentIdRef = useRef(null);
    const cropperRef = useRef(null);
    const courceRef = useRef(null);

    const navigate = useNavigate();

    const formikCreate = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            date_of_birth: "",
            sex: "",
            email: "",
            faculty: "",
            // type_of_learning: "",
            phone_number: "",
            image: "",
            password: "",
            re_password: "",
            personal_data: false,
            notification_email: true,
            vk: "",
            telegram:"",
        },
        validationSchema: Yup.object({
            last_name: Yup.string()
                .test("name", `Введите корректную фамилию`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы")
                .required("Введите фамилию"),
            first_name: Yup.string()
                .test("name", `Введите корректное имя`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы")
                .required("Введите имя"),
            middle_name: Yup.string()
                .test("name", `Введите корректное отчество`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            // last_name_translated: Yup.string()
            //     .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит фамилии")
            //     .required("Укажите транслит фамилии"),
            // first_name_translated: Yup.string()
            //     .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит имени")
            //     .required("Укажите транслит имени"),
            // middle_name_translated: Yup.string().matches(
            //     regExpList.LATIN_REG_EXP,
            //     "Введите корректный транслит отчества"
            // ),
            date_of_birth: Yup.string()
                .required("Укажите дату рождения")
                .matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите корректную дату")
                .test("name", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidation(value)
                )
                .test("name1", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationDate(value)
                ),
            sex: Yup.string().required("Укажите пол"),
            faculty: Yup.string().required("Укажите регион"),
            // type_of_learning: Yup.string().required("Укажите орган молодёжного самоуправления"),
            phone_number: Yup.string()
                .matches(regExpList.PHONE_REG_EXP, "Введите правильный номер")
                .required("Укажите номер телефона"),
            email: Yup.string().email("Введите корректный email").required("Введите email"),
            password: Yup.string()
                .min(8, "Пароль должен содержать не менее 8 символов")
                .matches(regExpList.PASSWORD_REG_EXP, "Пароль должен содержать буквы и цифры")
                .required("Введите пароль"),
            re_password: Yup.string()
                .required("Повторите пароль")
                .min(8, "Минимальный пароль 8 символов")
                .oneOf([Yup.ref("password")], "Пароли должны совпадать"),
            personal_data: Yup.bool().oneOf([true], "Согласитесь с условиями"),
            notification_email: Yup.bool().oneOf([true], "Согласитесь с условиями"),        
            vk: Yup.string()
                .required("Введите ссылку")
                .test("vk", `Введите корректную ссылку`, value => CustomValidationUser.customVkUrlValidation(value)),
            telegram: Yup.string()
                .required("Введите ссылку")
                .test("telegram", `Введите корректную ссылку`, value => CustomValidationUser.customTelegramUrlValidation(value)),
            //     // .matches(regExpList.LATIN_REG_EXP, "Возможно использование только кириллицы")
            //     .required("Введите ссылку"),
        }),

        onSubmit: async values => {
            await setSubmitInfo(values);
        },
    });

    async function setSubmitInfo(values) {
        if (!fetch) {
            setFetch(true);
            const formData = new FormData();
            for (let item of Object.entries(values)) {
                if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                    const reverse = item[1].split(".").reverse().join("-");
                    formData.append(item[0], reverse);
                } else if (item[0] === "image") {
                    if (croppedFile !== undefined) {
                        formData.set("image", croppedFile);
                    }
                } else {
                    formData.append(item[0], item[1]);
                }
            }
            if (croppedFile !== undefined) {
                formData.set("image", croppedFile);
            }

            const responce = await HTTP.createUser(REQUEST_URL.createUser, formData);
            if (responce.id) {
                setRegistred(true);
            } else {
                setRegistrationErrors(responce);
            }
            setFetch(false);
        }
    }

    useEffect(() => {
        SetFieldsStatus.returnStatus(formikCreate, "student_id", formikCreate.values.has_student_id);
    }, [formikCreate.values.has_student_id]);

    useEffect(() => {
        if (croppedData !== "") {
            formikCreate.values.image = croppedData;
            setCroppedFile(ImageLoader.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData]);

    useEffect(() => {
        if (formikCreate.values.faculty !== "") {
            setDisableFields(false);
            formikCreate.values.academic_level = "";
            formikCreate.values.grade_level = "";
            // formikCreate.values.type_of_learning = "";
            // type_of_learning_ref.current.selectedIndex = 0;
            // academic_level_ref.current.selectedIndex = 0;
            // courceRef.current.selectedIndex = 0;
        } else {
            setDisableFields(true);
        }
    }, [formikCreate.values.faculty]);

    useEffect(() => {
        if (formikCreate.values.academic_level !== "") {
            setDisableGradeLevel(false);
        } else {
            setDisableGradeLevel(true);
        }
    }, [formikCreate.values.academic_level]);

    // useEffect(() => {
    //     if (formikCreate.values.has_student_id) {
    //         formikCreate.values.student_id = "";

    //         studentIdRef.current.style.pointerEvents = "none";
    //     } else {
    //         SetFieldsStatus.returnStatus(formikCreate, "student_id", formikCreate.values.has_student_id);
    //         studentIdRef.current.style.pointerEvents = "";
    //     }
    // }, [formikCreate.values.student_id, formikCreate.values.has_student_id]);

    useEffect(() => {
        if (formikCreate.values.academic_level !== "") {
            const countOfYears = Functions.getCountYear(formikCreate.values.academic_level);
            if (formikCreate.values.grade_level > countOfYears || formikCreate.values.grade_level <= 0) {
                formikCreate.values.grade_level = "";
            }
        } else {
            // courceRef.current.selectedIndex = 0;
            formikCreate.values.grade_level = "";
        }
    }, [formikCreate.values.academic_level, formikCreate.values.grade_level]);


    useEffect(() => {
        if (canCrop) {
            setModalStatus("photocrop");
        }
    }, [canCrop]);

    useEffect(() => {
        hideScroll(modalStatus);
    }, [modalStatus]);

    return (
        <>
            <div className="registration">
                <div className="container">
                    <div className="reg__content">
                        <div className="logoGroup-wrapper">
                            <LogoGroup />
                        </div>
                        <div className="regForm">
                            <form className="regForm__fields" onSubmit={formikCreate.handleSubmit}>
                                <div className="regForm__top">
                                    <div className="regForm__inputs-container">
                                        <Input
                                            id="last_name"
                                            required={true}
                                            {...formikCreate.getFieldProps("last_name")}
                                            translitOptions={formikCreate.getFieldProps("last_name_translated")}
                                            type="text"
                                            text="Фамилия"
                                            placeholder="Иванов"
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "last_name")}
                                        />
                                        <Input
                                            id="first_name"
                                            required={true}
                                            {...formikCreate.getFieldProps("first_name")}
                                            translitOptions={formikCreate.getFieldProps("first_name_translated")}
                                            type="text"
                                            text="Имя"
                                            placeholder="Иван"
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "first_name")}
                                        />
                                        <Input
                                            id="middle_name"
                                            required={false}
                                            {...formikCreate.getFieldProps("middle_name")}
                                            translitOptions={formikCreate.getFieldProps("middle_name_translated")}
                                            type="text"
                                            text="Отчество"
                                            placeholder="Иванович"
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "middle_name")}
                                        />
                                        <div className="regForm__inputs-container__group">
                                            <Select
                                                mode="sex"
                                                id="sex"
                                                required={true}
                                                text="Пол"
                                                status={SetFieldsStatus.returnStatus(formikCreate, "sex")}
                                                {...formikCreate.getFieldProps("sex")}
                                                formError={formikCreate}
                                            />

                                            <MaskedCustomInput
                                                type="text"
                                                text="Дата рождения"
                                                placeholder="01.01.2001"
                                                required={true}
                                                id="date_of_birth"
                                                {...formikCreate.getFieldProps("date_of_birth")}
                                                formError={formikCreate}
                                                mask={"99.99.9999"}
                                                status={SetFieldsStatus.returnStatus(formikCreate, "date_of_birth")}
                                            />
                                        </div>
                                        <Input
                                            text="Почта"
                                            required={true}
                                            id="email"
                                            placeholder="ivano@mail.ru"
                                            {...formikCreate.getFieldProps("email")}
                                            formError={formikCreate}
                                            value={formikCreate.values.email}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "email")}
                                        />
                                        <MaskedCustomInput
                                            text="Номер телефона"
                                            placeholder="+7 (900) 000 00 00"
                                            required={true}
                                            id="phone_number"
                                            {...formikCreate.getFieldProps("phone_number")}
                                            formError={formikCreate}
                                            mask={"+7(999) 999-99-99"}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "phone_number")}
                                        />

                                        <Input
                                            text="Ссылка в Вконтакте"
                                            placeholder="https://vk.com/id00000000"
                                            required={true}
                                            id="vklink"
                                            {...formikCreate.getFieldProps("vk")}
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "vk")}
                                        />

                                        <Input
                                            text="Ссылка на Telegram"
                                            placeholder="https://t.me/ivanov"
                                            required={true}
                                            id="telegram"
                                            {...formikCreate.getFieldProps("telegram")}
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "telegram")}
                                        />


                                        <Select
                                            list={faculties}
                                            required={true}
                                            text="Регион"
                                            id="faculty"
                                            defaultValue={"Выберите регион"}
                                            {...formikCreate.getFieldProps("faculty")}
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "faculty")}
                                        />
                                        <div></div>
                                        <Input
                                            mode="password_toggle"
                                            form="formikCreate"
                                            autocomplete="new-password"
                                            text="Пароль"
                                            placeholder="Введите пароль"
                                            required={true}
                                            id="password"
                                            {...formikCreate.getFieldProps("password")}
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "password")}
                                        />
                                        <Input
                                            mode="password_toggle"
                                            text="Повторите пароль"
                                            placeholder="Повторите пароль"
                                            required={true}
                                            id="re_password"
                                            {...formikCreate.getFieldProps("re_password")}
                                            formError={formikCreate}
                                            status={SetFieldsStatus.returnStatus(formikCreate, "re_password")}
                                        />
                                        <div className="regForm__inputs-container__agreements-wrapper">
                                            <Radio
                                                id="personal_data"
                                                mode="html"
                                                text="Соглашаюсь на <a target='_blank' href='https://molprav.ru/personal-data' >обработку моих персональных данных</a>, с <a target='_blank' href='https://molprav.ru/terms'>правилами пользования сайтом</a> ознакомлен и принимаю <a target='_blank' href='https://molprav.ru/privacy'>Пользовательское соглашение</a>.<span>*</span>"
                                                required={true}
                                                checked={formikCreate.values.personal_data}
                                                {...formikCreate.getFieldProps("personal_data")}
                                            />
                                            {formikCreate.errors.personal_data && (
                                                <p style={{ color: "#9C0B26", fontSize: "12px", marginTop: "5px" }}>
                                                    {formikCreate.errors.personal_data}
                                                </p>
                                            )}
                                        </div>
                                        <div className="regForm__inputs-container__agreements-wrapper">
                                            <Radio
                                                id="notification_email"
                                                text="Я даю согласие на получение информации по Email"
                                                required={true}
                                                checked={formikCreate.values.notification_email}
                                                {...formikCreate.getFieldProps("notification_email")}
                                            />
                                            {formikCreate.errors.notification_email && (
                                                <p style={{ color: "#9C0B26", fontSize: "12px", marginTop: "5px" }}>
                                                    {formikCreate.errors.notification_email}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="reg__content-label">
                                    <span>*</span> Поля обязательные к заполнению
                                </div>
                                <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                                <Button className="reg__content-button" text="СОЗДАТЬ" type="submit" color="blue" onClick={formikCreate.handleSubmit}/>
                                <NavLink to="/" className="main-reset-button-return">
                                            Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                                        </NavLink>
                            </form>
                        </div>
                    </div>
                </div>

                <Modals page="registration" setModalStatus={setModalStatus} modalStatus={modalStatus} />
                <Footer />
            </div>
            {registred && (
                <div className="overlayed" onClick={() => navigate("/")}>
                    <div className="notif-container">
                        <Success/>
                        <div className="notif-container__title">Регистрация пройдена успешно!</div>
                            <div className="notif-container__description">Вам на почту отправлено письмо с активацией аккаунта. Ссылка будет активна в течение 3 дней. Для
                            продолжения следуйте инструкциям в вашем аккаунте</div>
                        <NavLink to="/" className="main-reset-button-return">
                            Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
}
