// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 180px;
}
.not-content__content {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  align-items: center;
  justify-content: center;
}
.not-content__text {
  color: #2c2c59;
  text-align: center;
  font-family: "Roboto";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/components/NotContent/NotContent.scss","webpack://./src/components/NotContent/NotContent.css"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,iBAAA;ACCF;ADAE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;ACEJ;ADCE;EACE,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;ACCJ","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.not-content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-top: 180px;\n  &__content {\n    display: flex;\n    flex-direction: column;\n    max-width: 330px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &__text {\n    color: $text-purple;\n    text-align: center;\n    font-family: \"Roboto\";\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 36px;\n  }\n}\n",".not-content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-top: 180px;\n}\n.not-content__content {\n  display: flex;\n  flex-direction: column;\n  max-width: 330px;\n  align-items: center;\n  justify-content: center;\n}\n.not-content__text {\n  color: #2c2c59;\n  text-align: center;\n  font-family: \"Roboto\";\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 36px;\n}/*# sourceMappingURL=NotContent.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
