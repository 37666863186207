// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DAS-scrollbar {
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: rgba(44, 44, 89, 0.38);
}
.DAS-scrollbar::-webkit-scrollbar, .DAS-scrollbar > ::-webkit-scrollbar {
  background: white;
  border-radius: 100px;
  width: 11px;
  height: 11px;
}
.DAS-scrollbar::-webkit-scrollbar-button, .DAS-scrollbar > ::-webkit-scrollbar-button {
  padding: 0;
  display: none;
}
.DAS-scrollbar::-webkit-scrollbar-track, .DAS-scrollbar > ::-webkit-scrollbar-track {
  background-color: white;
  padding: 0px 8px 4px 8px;
}
.DAS-scrollbar::-webkit-scrollbar-thumb, .DAS-scrollbar > ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(44, 44, 89, 0.38);
}`, "",{"version":3,"sources":["webpack://./src/UI/Scroolbar/Scrollbar.scss","webpack://./src/UI/Scroolbar/Scrollbar.css"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,WAAA;EACA,qBAAA;EACA,uCAAA;ACDF;ADGE;EAEE,iBAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;ACFJ;ADKE;EAEE,UAAA;EACA,aAAA;ACJJ;ADOE;EAGE,uBAAA;EACA,wBAAA;ACPJ;ADWE;EAEE,kBAAA;EACA,wCAAA;ACVJ","sourcesContent":["@import \"../../styles/variables.scss\";\n\n.DAS-scrollbar {\n  overflow: auto;\n  width: 100%;\n  scrollbar-width: thin;\n  scrollbar-color: rgba(44, 44, 89, 0.38);\n\n  &::-webkit-scrollbar,\n  & > ::-webkit-scrollbar {\n    background: white;\n    border-radius: 100px;\n    width: 11px;\n    height: 11px;\n  }\n\n  &::-webkit-scrollbar-button,\n  & > ::-webkit-scrollbar-button {\n    padding: 0;\n    display: none;\n  }\n\n  &::-webkit-scrollbar-track,\n  & > ::-webkit-scrollbar-track {\n    // border-radius: 6px;\n    background-color: white;\n    padding: 0px 8px 4px 8px;\n    // margin-left: 8px;\n  }\n\n  &::-webkit-scrollbar-thumb,\n  & > ::-webkit-scrollbar-thumb {\n    border-radius: 6px;\n    background-color: rgba(44, 44, 89, 0.38);\n  }\n}\n",".DAS-scrollbar {\n  overflow: auto;\n  width: 100%;\n  scrollbar-width: thin;\n  scrollbar-color: rgba(44, 44, 89, 0.38);\n}\n.DAS-scrollbar::-webkit-scrollbar, .DAS-scrollbar > ::-webkit-scrollbar {\n  background: white;\n  border-radius: 100px;\n  width: 11px;\n  height: 11px;\n}\n.DAS-scrollbar::-webkit-scrollbar-button, .DAS-scrollbar > ::-webkit-scrollbar-button {\n  padding: 0;\n  display: none;\n}\n.DAS-scrollbar::-webkit-scrollbar-track, .DAS-scrollbar > ::-webkit-scrollbar-track {\n  background-color: white;\n  padding: 0px 8px 4px 8px;\n}\n.DAS-scrollbar::-webkit-scrollbar-thumb, .DAS-scrollbar > ::-webkit-scrollbar-thumb {\n  border-radius: 6px;\n  background-color: rgba(44, 44, 89, 0.38);\n}/*# sourceMappingURL=Scrollbar.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
