// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documents-list {
  margin-top: 20px;
}
.documents-list__wrapper {
  display: flex;
  max-width: 980px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentsList/DocumentsList.scss","webpack://./src/components/DocumentsList/DocumentsList.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;ACCF;ADCE;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;ACCJ","sourcesContent":[".documents-list {\n  margin-top: 20px;\n\n  &__wrapper {\n    display: flex;\n    max-width: 980px;\n    flex-direction: column;\n    background-color: #fff;\n    border-radius: 8px;\n  }\n}\n",".documents-list {\n  margin-top: 20px;\n}\n.documents-list__wrapper {\n  display: flex;\n  max-width: 980px;\n  flex-direction: column;\n  background-color: #fff;\n  border-radius: 8px;\n}/*# sourceMappingURL=DocumentsList.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
