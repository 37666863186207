// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.people-events__button {
  width: 180px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PeoplesEvent/PeoplesEvent.scss","webpack://./src/pages/PeoplesEvent/PeoplesEvent.css"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;ACAJ","sourcesContent":[".people-events {\n  &__button {\n    width: 180px;\n    height: 40px;\n  }\n}\n",".people-events__button {\n  width: 180px;\n  height: 40px;\n}/*# sourceMappingURL=PeoplesEvent.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
