// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.people-event {
  display: grid;
  grid-template-columns: 40px repeat(6, minmax(225px, 1fr)) 23px;
  justify-items: start;
  align-items: center;
  padding: 10px 8px;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
}
.people-event:last-child {
  border-bottom: none;
}
.people-event__text {
  width: 100%;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  overflow-x: hidden;
}
.people-event__icon {
  cursor: pointer;
}
.people-event__icon:hover * {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/PeoplesEventTable/PeopleEvent.scss","webpack://./src/components/PeoplesEventTable/PeopleEvent.css"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8DAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,gCAAA;ACCJ;ADCI;EACI,mBAAA;ACCR;ADEI;EACI,WAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;ACAR;ADGI;EACI,eAAA;ACDR;ADEQ;EACI,YAAA;ACAZ","sourcesContent":[".people-event {\n    display: grid;\n    grid-template-columns: 40px repeat(6, minmax(225px, 1fr)) 23px;\n    justify-items: start;\n    align-items: center;\n    padding: 10px 8px;\n    height: 64px;\n    border-bottom: 1px solid #ebebeb;\n\n    &:last-child {\n        border-bottom: none;\n    }\n\n    &__text {\n        width: 100%;\n        font-family: \"Roboto\";\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-transform: capitalize;\n        overflow-x: hidden;\n    }\n\n    &__icon {\n        cursor: pointer;\n        &:hover * {\n            opacity: 0.5;\n        }\n    }\n}\n",".people-event {\n  display: grid;\n  grid-template-columns: 40px repeat(6, minmax(225px, 1fr)) 23px;\n  justify-items: start;\n  align-items: center;\n  padding: 10px 8px;\n  height: 64px;\n  border-bottom: 1px solid #ebebeb;\n}\n.people-event:last-child {\n  border-bottom: none;\n}\n.people-event__text {\n  width: 100%;\n  font-family: \"Roboto\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  text-transform: capitalize;\n  overflow-x: hidden;\n}\n.people-event__icon {\n  cursor: pointer;\n}\n.people-event__icon:hover * {\n  opacity: 0.5;\n}/*# sourceMappingURL=PeopleEvent.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
