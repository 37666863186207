import "./UserAdditionalInfo.css";

import Input from "../../UI/Input/Input.jsx";
import Select from "../../UI/Select/Select";

export default function UserAdditionalInfo({
    mode = "default",
    startDate = null,
    endDate = null,
    organisationName = "",
    type_org = "",
    position = "",
    name_activity = "",
    organ_msu = "",
    region = "",
    isHead = false,
    file = "",
    status = "",
    comment = "",
})
{
    const customDateOfBirthValidation = (value) => {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            return newValue;
            // return Number(date - 14) > Number(newValue.slice(6, 10));
        }
        return false;
    };

    const setStatus = (status) => {
        switch (status) {
            case 'NEW':
              return 'Ожидает проверки';
            case 'Verified':
                return 'Проверка пройдена';
            case 'Rejected':
                return 'Отклонено модератором';
              case 'WAIT':
                return 'Ожидает изменения';
            default:
              console.log(`status error`);
              return "Неизвестен";
          }
    }

    const setStatusStyle = (status) => {
        switch (status) {
            case 'NEW':
              return 'user__additionaInfo-content__status_pending';
            case 'Verified':
                return 'user__additionaInfo-content__status_success';
            case 'Rejected':
                return 'user__additionaInfo-content__status_denied';
              case 'WAIT':
                return 'user__additionaInfo-content__status_change';
            default:
              return '';
          }
    }


    return (
        <>
            {mode === "default" && (
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div className="user__additionaInfo-date-wrapper">
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={customDateOfBirthValidation (startDate)}
                                />
                            </div>
                            <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={endDate == "9999-12-31"? "по н.в.": customDateOfBirthValidation(endDate)}
                                    />
                            </div>
                        </div>
                        <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                            <Input
                                mode="fake"
                                placeholder={organisationName}
                            />
                        </div>
                    </div>
                    <div className="user__additionaInfo-text user__additionaInfo-text-position">
                        <Input
                            mode="fake"
                            placeholder={position}
                        />
                    </div>
                </div>
            )}

            {mode === "internships" && (
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div class="user__additionaInfo__line-wrapper">
                    <div className={`user__additionaInfo-content__status ${setStatusStyle(status)}`}>Статус: <span>{setStatus(status)}</span></div>
                    <div className={`user__additionaInfo-content__status_comment`}>{comment}</div>
                        </div>
                        <div class="user__additionaInfo__line-wrapper">
                        <div className="user__participations__section-item-name user__participations__section-item-name__workType user__participations__section-workType">
                            <Input
                                mode="fake"
                                placeholder={name_activity}
                            />
                        </div>
                        </div>
                        <div class="user__additionaInfo__line-wrapper">

                        <div className="user__participations__section-item-name user__participations__section-workType">
                            <Input
                                mode="fake"
                                placeholder={type_org}
                            />
                        </div>
                        </div>
                        <div className="user__additionaInfo-date-wrapper">
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={customDateOfBirthValidation (startDate)}
                                />
                            </div>
                            <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={endDate == "9999-12-31"? "по н.в.": customDateOfBirthValidation(endDate)}
                                />
                            </div>
                        </div>


                    </div>

                        <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                            <Input
                                mode="fake"
                                placeholder={organisationName}
                            />
                        </div>
                    <div className="user__additionaInfo-text user__additionaInfo-text-position">
                        <Input
                            mode="fake"
                            placeholder={position}
                        />
                    </div>


                </div>
            )
            } 


{mode === "msu" && (
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div class="user__additionaInfo__line-wrapper">
                    <div className={`user__additionaInfo-content__status ${setStatusStyle(status)}`}>Статус: <span>{setStatus(status)}</span></div>
                    <div className={`user__additionaInfo-content__status_comment`}>{comment}</div>
                        </div>
                        <div class="user__additionaInfo__line-wrapper">
                        <div className="user__participations__section-item-name user__participations__section-item-name__workType user__participations__section-workType">
                            <Input
                                mode="fake"
                                placeholder={region != ""? region : "Регион не выбран"}
                            />
                        </div>
                        </div>
                        <div class="user__additionaInfo__line-wrapper">

                        <div className="user__participations__section-item-name user__participations__section-workType">
                            <Input
                                mode="fake"
                                placeholder={organ_msu != ""? organ_msu : "Орган не выбран"}
                            />
                        </div>
                        </div>
                        <div className="user__additionaInfo-date-wrapper">
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={customDateOfBirthValidation (startDate)}
                                />
                            </div>
                            <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                            <div className="user__additionaInfo-date">
                                <Input
                                    mode="fake"
                                    placeholder={endDate == "9999-12-31"? "по н.в.": customDateOfBirthValidation(endDate)}
                                />
                            </div>
                        </div>


                    </div>

                        <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                            <Input
                                mode="fake"
                                placeholder={organisationName}
                            />
                        </div>
                    <div className="user__additionaInfo-text user__additionaInfo-text-position">
                        <Input
                            mode="fake"
                            placeholder={position}
                        />
                    </div>


                </div>
            )
            } 
            
            
            {/* {mode === "msu" && (
                <div>
                    <div className="user__participations__section-data hidden">
                        <div className="user__participations__section-data__organisationType">
                            <Input
                                mode="fake"
                                placeholder={organisationType}
                            />
                        </div>
                        <div className="user__participations__section-data__region">
                            <Input
                                mode="fake"
                                placeholder={region}
                            /></div>
                        <div className="user__participations__section-data__document">{document}</div>
                    </div>

                    <div className="user__participations__section__wrapper">
                        <div className="user__participations__section-item">
                            <div className="user__participations__section-item-image">{logo}</div>
                            <div className="user__participations__section-item-name">
                                <Input
                                    mode="fake"
                                    placeholder={organisationName}
                                /></div>
                            <div className="user__participations__section-item-position">
                                <Input
                                    mode="fake"
                                    placeholder={position}
                                /></div>
                            <div className="user__additionaInfo-date-wrapper">
                                <div className="user__participations__section-date">
                                    <Input

                                        mode="fake"
                                        placeholder={startDate}
                                    />
                                </div>
                                <div className="user__participations__section"> - </div>
                                <div className="user__participations__section-date">

                                    <Input
                                        mode="fake"
                                        placeholder={endDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}

        </>
    );
}
