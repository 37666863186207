// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error__wrapper {
  display: flex;
  align-items: stretch;
  column-gap: 8px;
}
.error__image {
  width: 24px;
  height: 24px;
}
.error__text {
  font-size: 14px;
  color: #9C0B26;
  line-height: 22px;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/UI/ShowError/ShowError.scss","webpack://./src/UI/ShowError/ShowError.css"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,oBAAA;EACA,eAAA;ACAJ;ADGE;EACE,WAAA;EACA,YAAA;ACDJ;ADIE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;ACFJ","sourcesContent":[".error {\n  &__wrapper {\n    display: flex;\n    align-items: stretch;\n    column-gap: 8px;\n  }\n\n  &__image {\n    width: 24px;\n    height: 24px;\n  }\n\n  &__text {\n    font-size: 14px;\n    color: #9C0B26;\n    line-height: 22px;\n    margin-bottom: 0px;\n  }\n}\n",".error__wrapper {\n  display: flex;\n  align-items: stretch;\n  -moz-column-gap: 8px;\n       column-gap: 8px;\n}\n.error__image {\n  width: 24px;\n  height: 24px;\n}\n.error__text {\n  font-size: 14px;\n  color: #9C0B26;\n  line-height: 22px;\n  margin-bottom: 0px;\n}/*# sourceMappingURL=ShowError.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
