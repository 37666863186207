// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mgu__logo-group {
  width: 100%;
}
.mgu__logo-group-container {
  width: 100%;
  margin: 0 auto;
}
.mgu__logo-group__logo {
  width: 100%;
  margin: 0 auto;
  align-self: center;
}

@media (max-width: 1500px) {
  .mgu__logo-group-container {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LogoGroup/LogoGroup.scss","webpack://./src/components/LogoGroup/LogoGroup.css"],"names":[],"mappings":"AAGI;EACI,WAAA;ACFR;ADIQ;EACI,WAAA;EACA,cAAA;ACFZ;ADKQ;EACI,WAAA;EACA,cAAA;EACA,kBAAA;ACHZ;;ADgCA;EAGY;IACI,UAAA;EC/Bd;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\n\n.mgu {\n    &__logo-group {\n        width: 100%;\n\n        &-container {\n            width: 100%;\n            margin: 0 auto;\n        }\n\n        &__logo {\n            width: 100%;\n            margin: 0 auto;\n            align-self: center;\n        }\n    } \n    // &__text {\n    //     max-width: 290px;\n    //     font-family: Montserrat;\n    //     font-style: normal;\n    //     font-size: 32px;\n    //     letter-spacing: .02em;\n    //     color: $accent-color;\n    //     display: flex;\n    //     flex-direction: column;\n    //     align-items: flex-start;\n    //     &-lg {\n    //         font-size: 32px;\n    //         font-weight: 500;\n    //         line-height: 36px;\n    //         text-align: left;\n            \n    //     }\n    //     &-md {\n    //         font-size: 16px;\n    //         font-weight: 400;\n    //         line-height: 22px;\n    //         text-align: left;\n    //     }\n    // }\n}\n\n@media (max-width: 1500px) {\n    .mgu {\n        &__logo-group {\n            &-container {\n                width: 90%;\n            }\n        } \n    }\n}",".mgu__logo-group {\n  width: 100%;\n}\n.mgu__logo-group-container {\n  width: 100%;\n  margin: 0 auto;\n}\n.mgu__logo-group__logo {\n  width: 100%;\n  margin: 0 auto;\n  align-self: center;\n}\n\n@media (max-width: 1500px) {\n  .mgu__logo-group-container {\n    width: 90%;\n  }\n}/*# sourceMappingURL=LogoGroup.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
