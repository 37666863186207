import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import InputTranslate from "../../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import { ImageLoader } from "../../../interface/ImageLoader";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../../CropPhoto/CropPhoto";
import openNotification from "../MessagePopup";
import Button from "../../../UI/Button/Button";
import Select from "../../../UI/Select/Select";
import Radio from "../../../UI/Radio/Radio";
import Input from "../../../UI/Input/Input";
import DocumentsStudent from "../../DocumentsStudent/DocumentsStudent";
import { showRegistrationErrors, getIncludesValues } from "../../../interface/information-module";
import * as regExpList from "../../../interface/RegExpDictionary";
import CustomValidationUser from "../../../interface/Validation";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";
import DownloadBoxWrapper from "../../DownloadBox/DownloadBoxWrapper";
import FileInput from "../../FileInput/FileInput";
import FileInputList from "../../FileInput/FileInputList";
import { academic_level, COURCES_LIST } from "../../../assets/academic_level";
import { academicLevelSlice } from "../../../assets/academic_level_slice";
import { type_of_learning } from "../../../assets/type_of_learning";

import close_window from "../../../images/other/close_window.png";
import { faculty } from "../../../Redux/Actions/profile";

export default function AddMSUModal(props) {
    const { modalStatus, setModalStatus } = props;
    const addItem = props.addItem;

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [isError, setIsError] = useState(false); // стейт для ошибок загружаемых файлов
    const [error, setError] = useState([]); // ошибки загрузки
    const [isVisibleProgressBar, setisVisibleProgressBar] = useState(false);
    const [progressInfos, setProgressInfos] = useState(23);
    const [file, setFile] = useState();
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const inputRef = useRef(null);
    const [canCrop, setCanCrop] = useState(false);
    const faculties = useSelector(({ facultiesList }) => [

        ...facultiesList.facultiesList,
    ])
    const [faculList, setfaculList] = useState([
        { id: 0, title: "Выберите регион" },
    ]);
    
    const [region, setRegion] = useState();
    const [region_id, setRegionID] = useState();
    const [organ_msu, setOrgan] = useState("Выберите орган МСУ");
    const [tillNow, setTillNow] = useState(false);

    const formikAddUser = useFormik({
        initialValues: {
            start_date: "",
            end_date: "",
            organization_name: "",
            position: "",
            organ_msu: type_of_learning.length > 0 ? type_of_learning[0].id : "",
            faculty_id: "",
            region,
            file: null,
        },
        validationSchema: Yup.object({
            // start_date: Yup.string().required("Введите дату начала"),
            // end_date: Yup.string().required("Введите дату окончания"),
            start_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату начала")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-valid-date', 'Дата начала не может быть в будущем', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'

                const today = new Date();
                if (!value) return this.createError({ message: 'Введите правильную дату' });

                const [day, month, year] = value.split('.').map(Number);
                const selectedDate = new Date(year, month - 1, day);

                return selectedDate <= today || this.createError({ message: 'Дата начала не может быть в будущем' });
            }),
        end_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату окончания")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-greater', 'Дата окончания должна быть позже даты начала', function(value) {
                const { start_date } = this.parent;

                if (!start_date || value === 'по н.в.') {
                    return true; // Skip validation if start_date is incomplete or if end_date is 'по н.в.'
                }

                const [startDay, startMonth, startYear] = start_date.split('.').map(Number);
                const startDate = new Date(startYear, startMonth - 1, startDay);

                if (!value) return this.createError({ message: 'Введите правильную дату' });
                
                const [endDay, endMonth, endYear] = value.split('.').map(Number);
                const endDate = new Date(endYear, endMonth - 1, endDay);

                return endDate > startDate || this.createError({ message: 'Дата окончания должна быть позже даты начала' });
            }),
            // .test('is-valid-date', 'Дата окончания не может быть в будущем', function(value) {
            //     if (value === 'по н.в.') return true; // Allow 'по н.в.'

            //     const today = new Date();

            //     if (!value) {
            //         return this.createError({ message: 'Введите правильную дату' });
            //     }

            //     const [day, month, year] = value.split('.').map(Number);
            //     const selectedDate = new Date(year, month - 1, day);

            //     return selectedDate <= today || this.createError({ message: 'Дата окончания не может быть в будущем' });
            // }),
            organization_name: Yup.string().required("Введите название организации"),
            position: Yup.string().required("Введите название должности"),
            region: Yup.string().required("Выберите регион"),
            organ_msu: Yup.string().required("Выберите орган МСУ"),
            file: Yup.mixed().required("Загрузите файл"),
        }),
        onSubmit: async values => {
            // values.region = region;
            values.file = file;
            if (values.organ_msu === undefined || values.organ_msu === "" || values.organ_msu === null) {
                values.organ_msu = faculties.filter((item) => item.id == region_id)[0]?.type_of_learning[0].id;
            } else {
                values.organ_msu = values.organ_msu;
            }
            values.start_date = values.start_date.split(".").reverse().join("-");
            values.end_date = values.end_date === 'по н.в.' ? '9999-12-31' : values.end_date.split(".").reverse().join("-");
            await setSubmitInfo(values);
            // window.location.href = window.location.href;
            setModalStatus("none");

        },
    });

    async function setSubmitInfo(values) {
        setIsUploading(true)
        const formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            } else if (item[0] === "file") {
                formData.append(item[0], item[1]);
            }
            else {
                formData.append(item[0], item[1]);
            }

        }

        const responce = await HTTP.PostProgress(REQUEST_URL.useMSU, formData, changeProgress);

        if (responce.id) {

            addItem({
                id: responce.id,
                start_date: values.start_date,
                end_date: values.end_date,
                organization_name: values.organization_name,
                position: values.position,
                region: values.region,
                faculty_id: values.faculty_id,
                organ_msu: values.organ_msu,
                file: responce.file,
            });

            openNotification({
                title: "Добавление юзера",
                text: "Задача успешно выполнена",
                type: "success",
            });
            setModalStatus("none");
        } else {
            openNotification({
                title: "Добавление юзера",
                text: "Что-то пошло не так",
                type: "error",
            });
            setRegistrationErrors(responce);
        }
        setIsUploading(false)
    }
    // useEffect(() => {
    //     setModalStatus("add_profile");
    // }, []);

    useEffect(() => {
        if (region_id !== "") {
            setDisableFields(false);
        } else {
            setDisableFields(true);
        }
    }, [region_id]);

    useEffect(() => {
        setfaculList((prevTypeOrg) => [...prevTypeOrg, ...faculties])
    }, []);

    // useEffect(() => {
    //     if (formikAddUser.values.academic_level !== "") {
    //         setDisableGradeLevel(false);
    //     } else {
    //         setDisableGradeLevel(true);
    //     }
    // }, [formikAddUser.values.academic_level]);

    const handleRegionChange = (e) => {
        setRegionID(e.target.value);
        setRegion(e.target.options[e.target.selectedIndex].text);
        setOrgan("Выберите орган МСУ")
        formikAddUser.values.region = e.target.value;
    };

    const handleOrganChange = (e) => {
        setOrgan(e.target.value)
        formikAddUser.values.organ_msu = e.target.value;
    };

    const changeProgress = (progress) => {
        setProgress(progress);
    };

    const handleTillNowChange = () => {
        setTillNow(!tillNow);
        if (!tillNow) {
            formikAddUser.setFieldValue('end_date', 'по н.в.');
        } else {
            formikAddUser.setFieldValue('end_date', '');
        }
    };

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div
                    className="modal-close"
                    onClick={() => {
                        setModalStatus("none");
                    }}
                >
                    <img src={close_window} alt="Close" />
                </div>

                <form
                    className={
                        modalStatus !== "material_support" && modalStatus !== "change_faculty"
                            ? "modalForms-mainEdit change-modal"
                            : "modalForms-mainEdit"
                    }
                >
                    <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                        <div className="modalForms-header-image">

                        </div>
                        {/* <div className="modalForms-header-info">
                            <div className="modalForms-header-title">Подтвеждающий документ</div>
                        </div> */}
                    </div>

                    <Select
                        required={true}
                        id="region"
                        name="region"
                        // {...formikAddUser.getFieldProps("region")}
                        text="Регион"
                        list={faculList}
                        disable={false}
                        mode={"default2"}
                        onChange={handleRegionChange}
                        defaultValue={"Выберите регион"}
                        formError={formikAddUser}
                    />
                    <Select
                        list={[
                            { id: "", title: "Выберите орган МСУ" },
                            ...(region_id === ""
                                ? type_of_learning
                                : (faculties.filter((item) => item.title == region_id))[0]?.type_of_learning || []
                            )
                        ]}
                        required={true}
                        text="Орган МСУ в"
                        id="organ_msu"
                        name="organ_msu"
                        mode={"default2msu"}
                        disable={disableFields}
                        defaultValue={organ_msu}
                        // {...formikAddUser.getFieldProps("organ_msu")}
                        onChange={handleOrganChange}
                        formError={formikAddUser}
                    />

                    <div>
                        Прикрепите подтверждающий документ <br />
                        (Характеристика или письмо с места практики или приказ о приёме на работу)
                    </div>

                    <Input
                        required={true}
                        mode="fileUpload"
                        id="file"
                        name="file"
                        accept=".pdf"
                        refInput={inputRef}
                        {...formikAddUser.getFieldProps("file")}
                        formError={formikAddUser}
                        value={formikAddUser.values.file}
                        takeFile={(e) => {
                            setFile(e.target.files[0])
                            formikAddUser.values.file = e.target.files[0];
                        }}
                    // takeFile={e => setFile(e.target.files[0])}
                    />

                    {isUploading && (
                        <div>
                            <div style={{ width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                                <div style={{ width: `${progress}%`, height: '30px', backgroundColor: '#4caf50', borderRadius: '5px' }} />
                            </div>
                            <p>Загрузка файла: {progress.toFixed(2)}%</p>
                        </div>
                    )}

                    <Input
                        text="Наименование организации"
                        required={true}
                        id="organization_name"
                        name="organization_name"
                        {...formikAddUser.getFieldProps("organization_name")}
                        formError={formikAddUser}
                        value={formikAddUser.values.organization_name}
                    />
                    <div className="modal-date__wrapper">

                        <MaskedCustomInput
                            type="text"
                            text="Срок начала полномочий"
                            required={true}
                            id="start_date"
                            name="start_date"
                            {...formikAddUser.getFieldProps("start_date")}
                            formError={formikAddUser}
                            mask={"99.99.9999"}
                        />
                        <MaskedCustomInput
                            type="text"
                            text="Срок окончания полномочий"
                            required={true}
                            id="end_date"
                            name="end_date"
                            {...formikAddUser.getFieldProps("end_date")}
                            formError={formikAddUser}
                        mask={tillNow ? "" : "99.99.9999"}
                        />
                        {/* <div className="user__participations__section__organisationType">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={tillNow}
                                    onChange={handleTillNowChange}
                                />
                                По настоящее время
                            </label>
                        </div> */}
                    </div>
                    <Input
                        type="text"
                        text="Должность"
                        required={true}
                        id="position"
                        name="position"
                        {...formikAddUser.getFieldProps("position")}
                        formError={formikAddUser}
                    />
                    <button type="button" className="button button_blue" color="blue" onClick={formikAddUser.handleSubmit}>Добавить значение</button>
                    <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                </form>
            </div>
            {/* 
        <Modals
          page="registration"
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
        /> */}
        </>
    );
}

// AddProfileModal
