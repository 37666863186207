import { useState, useEffect, useRef } from "react";
import ShowError from "../ShowError/ShowError";

import { SetFieldsStatus } from "../../interface/SetFieldsStatus";

import { ReactComponent as TogglePassword } from "../../images/other/eye_slash.svg";
import { ReactComponent as EyeIcon } from "../../images/other/eye_icon.svg";

import "./Input.css";

export default function Input({
  type = "",
  value = "",
  defaultValue = "",
  defaultValueTranslit = "",
  translitOptions,
  disabled = false,
  mode = "default",
  status = "default",
  form = "",
  ref,
  textTranslit,
  placeholder,
  placeholderTranslit,
  id = "",
  onBlur = () => { },
  onChange = () => { },
  onFocus = () => { },
  onKeyDown = () => { },
  takeFile = () => { },
  text = "",
  required,
  showError = true,
  formError,
  refInput = null,
  checked = false,
  maxLength = "",
  className = null,
  name = "",
  getInitialValues,
  accept,
  setUrl,
  multiple = false,
}) {
  const [passwordInputVisible, setPasswordInputVisible] = useState(false);
  const [focuse, setFocuse] = useState(false);
  const inputRef = useRef();

  const setImageClass = (status) => {
    switch (status) {
      case "default":
        return "";
      case "valid":
        return "password__image-success";
      case "not_valid":
        return "password__image-error";
      default:
        return "";
    }
  };



  // useEffect(() => {
  //   getInitialValues(id)
  // }, [id])

  return (
    <>
      {mode === "default" && (
        <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)} ${className}`}>
          {text !== "" && (
            <label htmlFor="">
              {text} <span>{required ? "*" : ""}</span>
            </label>
          )}
          <input
            type={type}
            maxLength={maxLength}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            id={id}
            placeholder={placeholder}
            ref={refInput}
            name={name}
            disabled={checked === false ? false : true}
          />
          {maxLength && <span className="input-wrapper-length">{maxLength}</span>}
          {SetFieldsStatus.setImage(status)}
          {showError && <ShowError form={formError} name={id} />}
        </div>
      )}
      {mode === "password_toggle" && (
        <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)}`}>
          <label htmlFor="">
            {text} <span>{required ? "*" : ""}</span>

          </label>
          <div className="input-wrapper-password_toggle">
            <input type={passwordInputVisible ? "text" : "password"} onBlur={onBlur} onChange={onChange} id={id} placeholder={placeholder}
            />

            {passwordInputVisible ? (
              <EyeIcon
                className={`${"password__image"} ${setImageClass(status)}`}
                onClick={() => setPasswordInputVisible(!passwordInputVisible)}
              />
            ) : (
              <TogglePassword
                onClick={() => setPasswordInputVisible(!passwordInputVisible)}
                className={`${"password__image"} ${setImageClass(status)}`}
              />
            )}
            {/* <img
              src={passwordInputVisible ? eye_icon : togglePassword}
              alt="toggle password visible"
              onClick={() => setPasswordInputVisible(!passwordInputVisible)}
            /> */}
          </div>
          {/* {SetFieldsStatus.setImage(status)} */}
          {showError && <ShowError form={formError} name={id} />}
        </div>
      )}
      {mode === "login" && (
        <div className={"input-wrapper-login"}>
          <input type={type} placeholder={placeholder} onBlur={onBlur} onChange={onChange} id={id} />
          {SetFieldsStatus.setImage(status)}
          {showError && <ShowError form={formError} name={id} />}
        </div>
      )}
      {mode === "login_passwordToggle" && (
        <div className={"input-wrapper-login input-wrapper-login_toggle"}>
          <input
            type={passwordInputVisible ? "text" : "password"}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            id={id}
          />
          {passwordInputVisible ? (
            <EyeIcon
              className={`${"password__image"} ${setImageClass(status)}`}
              onClick={() => setPasswordInputVisible(!passwordInputVisible)}
            />
          ) : (
            <TogglePassword
              onClick={() => setPasswordInputVisible(!passwordInputVisible)}
              className={`${"password__image"} ${setImageClass(status)}`}
            />
          )}
          {/* <img
            src={passwordInputVisible ? eye_icon : togglePassword}
            alt="toggle password visible"
            onClick={() => setPasswordInputVisible(!passwordInputVisible)}
          /> */}
          {SetFieldsStatus.setImage(status)}
          {showError && <ShowError form={formError} name={id} />}
        </div>
      )}
      {mode === "fake" && (
        <div className={`${"input-wrapper_fake"} ${`input-wrapper-${className}`}`}>
          <div className="input-fake__label">{text}</div>
          <div className={`${"input-fake__input"} ${`input-fake__input-${className}`}`}
          >{placeholder}</div>
        </div>
      )}
      {mode === "fake_translit" && (
        <>
          <div className={`${"input-wrapper_fake"} ${`input-wrapper-${className}`}`}>
            <div className="input-fake__label">{text}</div>
            <div className="input-fake__input">{placeholder}</div>
          </div>
          <div className={`${"input-wrapper_fake"} ${`input-wrapper-${className}`}`}>
            <div className="input-fake__label">{textTranslit}</div>
            <div className="input-fake__input">{placeholderTranslit}</div>
          </div>
        </>
      )}

      {mode === "datePayment" && (
        <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)}`}>
          <label htmlFor="">{text}</label>
          <input type={type} value={value} onBlur={onBlur} onChange={onChange} id={id} onFocus={onFocus} />
          {/* {showError && <ShowError form={formError} name={id} />} */}
        </div>
      )}
      {mode === "question" && (
        <div className={`${"input-wrapper input-wrapper_question"} `}>
          <input
            className="input-wrapper__input-question"
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            id={id}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      )}
      {mode === "file" && (
        <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)} ${className}`}>
          <input
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={() => setFocuse(true)}
            id={id}
            placeholder={placeholder}
            ref={refInput}
            disabled={checked === false ? false : true}
          />
          {focuse !== true && (
            <span
              onClick={() => setFocuse(true)}
              className={`${focuse ? "input-wrapper-placeholder input-wrapper-placeholder-hide" : "input-wrapper-placeholder"
                }`}
            >
              Укажите название документа<span className="input-wrapper-span">*</span>
            </span>
          )}

          {SetFieldsStatus.setImage(status)}
          {showError && <ShowError form={formError} name={id} />}
        </div>
      )}

      {mode === "fileUpload" && (
        <div>
        <label htmlFor={id}>
          <input
            onChange={e => takeFile(e)}
            datatype={"pdf"}
            type="file"
            accept={accept}
            multiple={multiple}
            name={name}
            ref={ref}
            id={id}
          />
        </label>
        {showError && <ShowError form={formError} name={id} />}
        </div>
      )}

{mode === "fileChange" && (
  <div className="file_changeInfo">
        <label htmlFor={id}>Изменить</label>
          <input
            onChange={e => takeFile(e)}
            datatype={"pdf"}
            type="file"
            accept={accept}
            multiple={multiple}
            name={name}
            ref={ref}
            id={id}
          />
        </div>
      )}


      {mode === "time" && (
        <>
          <div className="questionnaire__time-wrapper">
            <div className="questionnaire__time-input" id="input_time">
              <input
                type={type}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                id={id}
                placeholder={placeholder}
                ref={refInput}
                onFocus={onFocus}
                disabled={disabled}
              // minlength="2"
              // maxlength="2"
              />
            </div>
            {/* {showError && <ShowError form={formError} name={id} />} */}
          </div>
        </>
      )}
    </>
  );
}
