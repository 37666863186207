import { useEffect } from "react";

import ShowError from "../ShowError/ShowError";

import { SetFieldsStatus } from "../../interface/SetFieldsStatus";

import "./Select.css";

export default function Select({
    list,
    defaultValue,
    mode = "default",
    status = "default",
    id,
    required,
    onBlur = () => {},
    onChange = () => {},
    text = "",
	value,
    formError,
    refInput = null,
    selectedItemKey = undefined,
    disable = false,
}) {

    const setOptions = () => {
        return list.map((item, index) => {
            if (index === 0) {
                return (
                    <option key={index} selected={defaultValue === item.title ? true : false} value="" disabled={true}>
                        {item.title}
                    </option>
                );
            }
            return (
                <option
                    key={index}
                    selected={String(defaultValue) === String(item.title) ? true : false}
                    value={item.title ? item.id : item.title}
                >
                    {item.title}
                </option>
            );
        });
    };

    const setOptions2 = () => {
        return list.map((item, index) => {
            if (index === 0) {
                return (
                    <option key={index} selected={String(defaultValue) === String(item.title) ? true : false} value="" disabled={true}>
                        {item.title}
                    </option>
                );
            }
            return (
                
                <option
                    key={index}
                    selected={String(defaultValue) === String(item.title) ? true : false}
                    value={item.title ? item.title : item.title}
                >
                    {item.title}
                </option>
            );
        });
    };

    const setOptionsEdit = () => {
        return list.map((item, index) => {
            if (index === 0) {
                return (
                    <option key={index} selected={defaultValue === item.title ? true : false} value="" disabled={true}>
                        {item.title}
                    </option>
                );
            }
            return (
                <option
                    key={index}
                    selected={defaultValue === item.id ? true : false}
                    value={item.title ? item.id : item.title}
                >
                    {item.title}
                </option>
            );
        });
    };
    const setOptionsProfileFaculty = () => {
        console.log(list)
        return list.map((item, index) => {
            if (index === 0) {
                return (
                    <option key={index} selected={defaultValue === item.title ? true : false} value="" disabled>
                        {item.title}
                    </option>
                );
            }
            return (
                <option
                    key={index}
                    selected={defaultValue === item.title ? true : false}
                    value={item.title ? item.title : item.title}
                >
                    {item.title}
                </option>
            );
        });
    };

    useEffect(() => {

	}, [value]);

    return (
        <>
            {mode === "default" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                    <select
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={refInput}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                        disabled={disable}
                        value={value}
                    >
                        {setOptions()}
                    </select>
                    <ShowError form={formError} name={id} />
                </div>
            )}
            {mode === "default2" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                    <select
                        id={id}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={refInput}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                        disabled={disable}
                        value={value}
                    >
                        {setOptions2()}
                    </select>
                    <ShowError form={formError} name={id} />
                </div>
            )}
            {mode === "default2msu" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                        
                    </label>
                    <select
                        id={id}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={refInput}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                        disabled={disable}
                        value={value}
                    >
                        {setOptions2()}
                    </select>
                    <p>Регион - молодёжное правительство, муниципальное образование - молодёжная администрация</p>
                    <ShowError form={formError} name={id} />
                </div>
            )}
            {mode === "editing" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                    <select
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={refInput}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                    >
                        {setOptionsEdit()}
                    </select>
                    <ShowError form={formError} name={id} />
                </div>
            )}
            {mode === "profileFaculty" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                    <select
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={refInput}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                    >
                        {setOptionsProfileFaculty()}
                    </select>
                    <ShowError form={formError} name={id} />
                </div>
            )}
            {mode === "sex" && (
                <div className="select-wrapper">
                    <label htmlFor="">
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                    <select
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                    >
                        <option key={1} value="" className="option" selected disabled>
                            Пол
                        </option>
                        <option key={2} selected={defaultValue === "MEN" ? true : false} value="MEN">
                            Мужской
                        </option>
                        <option key={3} selected={defaultValue === "WOMEN" ? true : false} value="WOMEN">
                            Женский
                        </option>
                    </select>
                    <ShowError form={formError} name={id} />
                </div>
            )}

                {mode === "profile" && (
                <div className="select-wrapper select-wrapper__profile"> 
                    <select
                        aria-label={text}
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        // ref={refInput}
                        // className={`${"select"} ${SetFieldsStatus.setSelectClassValid(status)}`}
                        required
                        readonly
                    >
                        {/* {setOptions()} */}
                    </select>
                </div>
            )}
        </>
    );
}
