export const peoplesResponses = [
  {
    id: 1,
    name: "Воскобойников М.М",
    time_stamp: "05.06.2023 11:27",
    about_person: "Я - хороший человек, который любит животных и путешествовать",
    slogan: "Все или ничего",
    selection_from_list: "Кошка",
    few_from_list: "Вискас, вода",
    drop_down_list: "Собака",
    uploading_files: "ссылка",
    date: "24.05.2023",
    time: "14:10",
  },

  // answers: [
  //   {
  //     id: 1,
  //     answer : ""
  //   },
  //   {
  //     id: 2,
  //     answer : ""
  //   },
  //   {
  //     id: 3,
  //     answer : ""
  //   },
  // ]
  {
    id: 2,
    name: "Воскобойников М.М",
    time_stamp: "05.06.2023 11:27",
    about_person: "Я - хороший человек, который любит животных и путешествовать",
    slogan: "Все или ничего",
    selection_from_list: "Кошка",
    few_from_list: "Вискас, вода",
    drop_down_list: "Собака",
    uploading_files: "ссылка",
    date: "24.05.2023",
    time: "14:10",
  },
  {
    id: 3,
    name: "Воскобойников М.М",
    time_stamp: "05.06.2023 11:27",
    about_person: "Я - хороший человек, который любит животных и путешествовать",
    slogan: "Все или ничего",
    selection_from_list: "Кошка",
    few_from_list: "Вискас, вода",
    drop_down_list: "Собака",
    uploading_files: "ссылка",
    date: "24.05.2023",
    time: "14:10",
  },
  {
    id: 4,
    name: "Воскобойников М.М",
    time_stamp: "05.06.2023 11:27",
    about_person: "Я - хороший человек, который любит животных и путешествовать",
    slogan: "Все или ничего",
    selection_from_list: "Кошка",
    few_from_list: "Вискас, вода",
    drop_down_list: "Собака",
    uploading_files: "ссылка",
    date: "24.05.2023",
    time: "14:10",
  },
];
