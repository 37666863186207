// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid #A9AAC2; /* Light grey */
  border-top: 10px solid #2C2C59; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  position: relative;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/UI/Loader/Loader.scss","webpack://./src/UI/Loader/Loader.css"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,0BAAA,EAAA,eAAA;EACA,8BAAA,EAAA,UAAA;EACA,kBAAA;EACA,uCAAA;ACDF;;ADIA;EACE,kBAAA;EACA,aAAA;EACA,aAAA;EAEA,uBAAA;EACA,mBAAA;ACFF;;ADKA;EACE;IACE,uBAAA;ECFF;EDIA;IACE,yBAAA;ECFF;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\n\n.loading-spinner {\n  width: 50px;\n  height: 50px;\n  position: absolute;\n  top: 50%;\n  left: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 10px solid $int-purple; /* Light grey */\n  border-top: 10px solid $text-purple; /* Black */\n  border-radius: 50%;\n  animation: spinner 1.5s linear infinite;\n}\n\n.spinner-container {\n  position: relative;\n  display: flex;\n  height: 400px;\n\n  justify-content: center;\n  align-items: center;\n}\n\n@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n",".loading-spinner {\n  width: 50px;\n  height: 50px;\n  position: absolute;\n  top: 50%;\n  left: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 10px solid #A9AAC2; /* Light grey */\n  border-top: 10px solid #2C2C59; /* Black */\n  border-radius: 50%;\n  -webkit-animation: spinner 1.5s linear infinite;\n          animation: spinner 1.5s linear infinite;\n}\n\n.spinner-container {\n  position: relative;\n  display: flex;\n  height: 400px;\n  justify-content: center;\n  align-items: center;\n}\n\n@-webkit-keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}/*# sourceMappingURL=Loader.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
