import { Functions } from "./functions";
import { DATE_OF_BIRTH_REG_EXP } from "./RegExpDictionary";

const URL_REG_EXP = /^https:\/\/[\w\-]+\.[\w\-]+(\/[\w\-\.~:\/?#[\]@!$&'()*+,;=]*)+$/;

// Регулярное выражение для ссылок вида https://vk.com/
const VK_URL_REG_EXP = /^https:\/\/vk\.com\/[\w\-]+$/;

// Регулярное выражение для ссылок вида https://t.me/
const TELEGRAM_URL_REG_EXP = /^https:\/\/t\.me\/[\w\-]+$/;

export default class CustomValidationUser {
    static customDateValidationReg(value, regExp) {
        if (value) {
            const newValue = value.split("-").reverse().join(".");
            return regExp.test(newValue);
        }
        return false;
    }

    static customDateOfBirthValidation(value) {
        const date = new Date().getFullYear();
        if (value !== undefined && String(value).slice(9, 10) !== "_") {
            return Number(date - 14) > Number(value.slice(6, 10));
        }
        return false;
    }

    static customDateOfBirthValidationDate(value) {
        if (value !== undefined && String(value).slice(9, 10) !== "_") {
            const formatValue = String(value).split(".").reverse().join("-");
            const date = new Date(String(formatValue));
            return (
                `${Functions.getMonthWithPrefix(date.getDate())}.${Functions.getMonthWithPrefix(
                    date.getMonth() + 1
                )}.${date.getFullYear()}` === String(value)
            );
        }
        return false;
    }

    static customNameValidation(value) {
        if (value === "" || value === undefined) {
            return true;
        }
        if (value !== undefined && value !== "") {
            const str = value.replace(/\d+/g, "");
            return str === value;
        }
        return false;
    }

    static customDateOfBirthValidationReg(value) {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            return DATE_OF_BIRTH_REG_EXP.test(newValue);
        }
        return false;
    }

    static customCheckedCheckboxValidationReg(value) {
        if (value.length > 0) {
            return true;
        }
        return false;
    }

    // Новый метод для валидации URL
    static customUrlValidation(value) {
        if (value === "" || value === undefined) {
            return true; // Разрешаем пустые ссылки, если нужно
        }
        return URL_REG_EXP.test(value);
    }

    // Валидация ссылки https://vk.com/
    static customVkUrlValidation(value) {
        if (value === "" || value === undefined) {
            return false; // Не разрешаем пустые значения
        }
        return VK_URL_REG_EXP.test(value);
    }

    // Валидация ссылки https://t.me/
    static customTelegramUrlValidation(value) {
        if (value === "" || value === undefined) {
            return false; // Не разрешаем пустые значения
        }
        return TELEGRAM_URL_REG_EXP.test(value);
    }
}
