// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-event {
  padding-top: 48px;
  max-width: 1508px;
  padding: 25px 20px;
  width: 100%;
  margin: 0 auto;
}
.create-event .box-white {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(169, 170, 194, 0.24);
}
.create-event__title {
  color: #0d0e13;
  margin-bottom: 16px;
}
.create-event .event-form {
  display: flex;
  gap: 20px;
}
.create-event .event-form__left {
  max-width: 720px;
  width: 100%;
}
.create-event .event-form__right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 20px;
}
.create-event .event-form__general {
  padding: 16px 20px;
}
.create-event .event-form__availability {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
}
.create-event .event-form__questions {
  padding: 16px 20px;
}
.create-event .event-form__title {
  color: #0d0e13;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}
.create-event .event-form__select {
  max-width: 100%;
}
.create-event .event-form__label label {
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.create-event .event-form__label-place {
  margin-bottom: 8px;
}
.create-event .event-form__label-place input {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(169, 170, 194, 0.5);
}
.create-event .event-form__label-place input::placeholder {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  line-height: 24px;
}
.create-event .event-form__label-place label {
  color: var(--base-day-500, #0d0e13);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.create-event .event-form__label input::placeholder {
  color: #7a828a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.14px;
}
.create-event .event-form__description {
  border-radius: 7px;
  background: #eef4ff;
  height: 82px;
  padding: 8px 16px;
  margin-bottom: 16px;
}
.create-event .event-form__explanation {
  height: 64px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(169, 170, 194, 0.5);
  margin-bottom: 16px;
}
.create-event .event-form__dates {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}
.create-event .event-form__dates .calendar {
  max-width: 100%;
}
.create-event .event-form__btn {
  width: 100%;
  margin-top: 17px;
}

@media (max-width: 1333px) {
  .event-form__dates {
    flex-wrap: wrap;
  }
}
@media (max-width: 1109px) {
  .create-event .event-form {
    flex-wrap: wrap;
  }
  .create-event .event-form__left {
    max-width: 100%;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./%D0%A1reateEventPage.scss","webpack://./%D0%A1reateEventPage.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;ACCF;ADCE;EACE,kBAAA;EACA,gBAAA;EACA,sDAAA;ACCJ;ADEE;EACE,cAAA;EACA,mBAAA;ACAJ;ADGE;EACE,aAAA;EAEA,SAAA;ACFJ;ADII;EACE,gBAAA;EACA,WAAA;ACFN;ADKI;EAEE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;ACJN;ADOI;EACE,kBAAA;ACLN;ADQI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EAGA,kBAAA;ACRN;ADWI;EACE,kBAAA;ACTN;ADcI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;ACZN;ADeI;EACE,eAAA;ACbN;ADiBM;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;ACfR;ADiBM;EACE,kBAAA;ACfR;ADgBQ;EACE,kBAAA;EACA,gBAAA;EACA,qDAAA;ACdV;ADgBU;EACE,0BAAA;EACA,eAAA;EACA,iBAAA;ACdZ;ADkBQ;EACE,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AChBV;ADoBM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AClBR;ADqBI;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;ACnBN;ADsBI;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,qDAAA;EACA,mBAAA;ACpBN;ADuBI;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;ACrBN;ADuBM;EACE,eAAA;ACrBR;ADyBI;EACE,WAAA;EACA,gBAAA;ACvBN;;AD4BA;EAEI;IACE,eAAA;EC1BJ;AACF;AD8BA;EAEI;IACE,eAAA;EC7BJ;ED8BI;IACE,eAAA;IACA,WAAA;EC5BN;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
