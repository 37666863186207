// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-event {
  margin-top: 48px;
  max-width: 1508px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 15px;
}
.page-event__container {
  display: flex;
  gap: 20px;
  margin-bottom: 182px;
}

.page-title {
  margin-bottom: 16px;
}

@media (max-width: 1226px) {
  .page-event__container {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Event/Event.scss","webpack://./src/pages/Event/Event.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;ACCF;ADAE;EACE,aAAA;EACA,SAAA;EACA,oBAAA;ACEJ;;ADEA;EACE,mBAAA;ACCF;;ADEA;EAEI;IACE,sBAAA;ECAJ;AACF","sourcesContent":[".page-event {\n  margin-top: 48px;\n  max-width: 1508px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 48px 15px;\n  &__container {\n    display: flex;\n    gap: 20px;\n    margin-bottom: 182px;\n  }\n}\n\n.page-title {\n  margin-bottom: 16px;\n}\n\n@media (max-width: 1226px) {\n  .page-event {\n    &__container {\n      flex-direction: column;\n    }\n  }\n}\n",".page-event {\n  margin-top: 48px;\n  max-width: 1508px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 48px 15px;\n}\n.page-event__container {\n  display: flex;\n  gap: 20px;\n  margin-bottom: 182px;\n}\n\n.page-title {\n  margin-bottom: 16px;\n}\n\n@media (max-width: 1226px) {\n  .page-event__container {\n    flex-direction: column;\n  }\n}/*# sourceMappingURL=Event.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
