import React, { useEffect, useRef, useState } from "react";
import "./Textarea.css";

const defaultStyle = {
    display: "block",
    overflow: "hidden",
    resize: "none",
    width: "100%",
};

const AutoHeightTextarea = ({ style = defaultStyle, setTextAreaValue, title = "", value = "", ...etc }) => {
    const textareaRef = useRef(null);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [currentValue]);

    const [valueArea, setvVlueArea] = useState("");

    const handleChange = (event) => {
        setvVlueArea(event.target.value);
        setTextAreaValue(event.target.value);
        event.target.current.style.height = `${event.target.scrollHeight}px`;
    };


    return (
        <div className="textarea">
            <label className="textarea_label">
              <p className="textarea_label-text">{title}</p>
            </label>
            <textarea
                className="textarea_input"
                ref={textareaRef}
                style={style}
                {...etc}
                value={value}
                onChange={handleChange}
                disabled
            />

        </div>

    );
};

export default AutoHeightTextarea;