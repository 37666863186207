import openNotification from "../components/Modals/MessagePopup";
import { FileDownload } from "./FileDownload";
export const SERVER_URL = "https://apilk.molprav.ru"; // Урл без слеша в конце

export class HTTP {
    static getToken() {
        return localStorage.getItem("token");
    }

    static setToken(token) {
        localStorage.setItem("token", token);
    }

    static deleteToken() {
        localStorage.removeItem("token");
    }

    static async getLogin(url, data) {
        const returnData = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(r => {
                // if (r.auth_token !== undefined){
                //     this.setToken(r.auth_token);
                // }
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        if (returnData.auth_token !== undefined) {
            this.setToken(returnData.auth_token);
        }
        return returnData;
    }

    static async activateAccount(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(r => {
            if (r.status === 204) {
                return r.ok;
            } else {
                return false;
            }
        });
        return responce;
    }

    static async passwordReset(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                // Authorization: `Token ${data.token}`,
            },
        });
        // .then(async r => {
        //   const data = await r.json();
        //   return {
        //     status: r.status,
        //     data: data
        //   };
        // });
        return responce;
    }

    static async logOut() {
        await fetch(SERVER_URL + "/api/auth/token/logout/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        }).then(r => {
            this.deleteToken();
            return r.ok;
        });
    }

    static async getUser(url) {
        const responce = await fetch(SERVER_URL + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        }).then(r => {
            return r.json();
        });
        return responce;
    }

    static async searchUser(url, params) {
        const getPathParams = pathParams => {
            const result = [];
            for (let item of Object.entries(pathParams)) {
                if (item[1] !== "") {
                    result.push(`${item[0]}=${item[1]}`);
                }
            }
            if (result.length > 1) {
                return `?${result.join("&")}`;
            }
            return `?${result[0]}`;
        };

        const responce = await fetch(SERVER_URL + url + getPathParams(params), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(r => {
            if (r.status === 200) {
                return r.json();
            } else {
                return false;
            }
        });
        return responce;
    }

    static async resetUser(url) {
        const responce = await fetch(SERVER_URL + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        }).then(r => {
            return r.status;
        });
        return responce;
    }

    static async Patch(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                return r;
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async Post(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async PostBody(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: data,
            headers: {
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async PostProgress(url, formData, changeProgress) {
        const totalBytes = formData.get("file").size; // Get the size of the file for progress tracking
        let uploadedBytes = 0;
    
        const stream = new ReadableStream({
            start(controller) {
                const reader = formData.get("file").stream().getReader();
                function push() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                        uploadedBytes += value.length; // Update uploaded bytes
                        controller.enqueue(value); // Enqueue the chunk
                        // Calculate and log progress
                        const progress = (uploadedBytes / totalBytes) * 100;
                        changeProgress(progress)
                        console.log(`Upload Progress: ${progress.toFixed(2)}%`);
                        push(); // Read the next chunk
                    }).catch(error => {
                        console.error('Error reading file:', error);
                        controller.error(error);
                    });
                }
                push();
            }
        });
    
        const response = await fetch(SERVER_URL + url, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Token ${this.getToken()}`,
            },
        })
        .then(r => r.json())
        .catch(error => {
            console.error('Error during file upload:', error);
        });
        
        return response;
    }
    static async PATCHProgress(url, formData, changeProgress) {
        const totalBytes = formData.get("file").size; // Get the size of the file for progress tracking
        let uploadedBytes = 0;
    
        const stream = new ReadableStream({
            start(controller) {
                const reader = formData.get("file").stream().getReader();
                function push() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                        uploadedBytes += value.length; // Update uploaded bytes
                        controller.enqueue(value); // Enqueue the chunk
                        // Calculate and log progress
                        const progress = (uploadedBytes / totalBytes) * 100;
                        changeProgress(progress)
                        console.log(`Upload Progress: ${progress.toFixed(2)}%`);
                        push(); // Read the next chunk
                    }).catch(error => {
                        console.error('Error reading file:', error);
                        controller.error(error);
                    });
                }
                push();
            }
        });
    
        const response = await fetch(SERVER_URL + url, {
            method: "PATCH",
            body: formData,
            headers: {
                Authorization: `Token ${this.getToken()}`,
            },
        })
        .then(r => r.json())
        .catch(error => {
            console.error('Error during file upload:', error);
        });
        
        return response;
    }
    static async PostPassword(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async PostFormForRequest(url, data, onUploadProgress) {
        let fileLoader = new FileDownload();
        const response = await fetch(SERVER_URL + url, {
            method: "POST",
            body: data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
            onUploadProgress,
        })
            .then(r => {
                fileLoader.saveFileName(r, "content-disposition", "filename=");
                return r.blob();
            })
            .then(data => {
                fileLoader.saveFile(data);
                fileLoader = null;
                return true;
            })
            .catch(error => {
                console.error(error);
                return false;
            });
        return response;
    }

    static async getDownloadFiles(url, data, fileHeaderString, method) {
        let fileLoader = new FileDownload();
        const response = await fetch(SERVER_URL + url, {
            method: method,
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                fileLoader.saveFileName(r, "content-disposition", fileHeaderString);
                return r.blob();
            })
            .then(data => {
                fileLoader.saveFile(data);
                fileLoader = null;
                openNotification({
                    title: "Задача",
                    text: "Задача успешно выполнена",
                    type: "success",
                });
                return true;
            })
            .catch(error => {
                openNotification({
                    title: "Задача",
                    text: "Операция не выполнена, обратитесь к разработчикам",
                    type: "error",
                });
                console.error(error);
                return false;
            });
        return response;
    }

    static async createUser(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: data,
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }
    
    static async updateEvent(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "PATCH",
            body: data,
            headers: {
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async createUserByAdmin(url, data) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: data,
            headers: {
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                return r.json();
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }
    static async DeleteBadPhoto(url, id) {
        const responce = await fetch(SERVER_URL + url, {
            method: "POST",
            body: JSON.stringify({id: id}),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                console.log(r);
                return r.status;
            })
            .catch(error => {
                console.error(error);
            });
        return responce;
    }

    static async Get(url, server = true) {
        const responce = await fetch(server ? SERVER_URL + url : url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // "Cache-Control": "no-cache, no-store, must-revalidate",
                Authorization: `Token ${this.getToken()}`,
            },
        }).then(r => {
            
            return r.json();
        });
        return responce;
    }

    static async GetFile(url) {
        let fileLoader = new FileDownload();
        let status = null;
        const responce = await fetch(SERVER_URL + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/octet-stream",
                Authorization: `Token ${this.getToken()}`,
            },
        })
            .then(r => {
                status = r.status;
                fileLoader.saveFileName(r, "content-disposition", "filename*=utf-8");
                return r.blob();
            })
            .then(data => {
                fileLoader.saveFile(data);
                fileLoader = null;
            });
        return status;
    }

    static async GetWithOutAuth(url) {
        const responce = await fetch(SERVER_URL + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(r => {
            return r.json();
        });
        return responce;
    }

    static async Update(method, form = false, url, data) {
        const resp = await fetch(SERVER_URL + url, {
            method: method,
            body: form ? JSON.stringify(data) : data,
            headers: form
                ? {
                      Authorization: `Token ${this.getToken()}`,
                      "Content-Type": "application/json",
                  }
                : {
                      Authorization: `Token ${this.getToken()}`,
                  },
        }).then(res => {
            if (res.status === 200 || res.status === 203 || res.status === 204) {
                openNotification({
                    title: "Задача",
                    text: "Задача успешно выполнена",
                    type: "success",
                });
                if (res.status === 204) {
                    return true;
                } else {
                    return res.json();
                }
            } else {
                openNotification({
                    title: "Задача",
                    text: "Операция не выполнена, обратитесь к разработчикам",
                    type: "error",
                });
            }
        });
        return resp;
    }

    static async UpdateUser(method, form = false, url, data) {
        const resp = await fetch(SERVER_URL + url, {
            method: method,
            // body: form ? JSON.stringify(data) : data,
            body: data,
            headers: form
                ? {
                      Authorization: `Token ${this.getToken()}`,
                    //   "Content-Type": "application/json",
                  }
                : {
                      Authorization: `Token ${this.getToken()}`,
                  },
        }).then(res => {
            return res.json();
        });
        return resp;
    }

    static async Delete(url) {
        await fetch(SERVER_URL + url, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${this.getToken()}`,
                "Content-Type": "application/json",
            },
        });
    }

    static async DeleteWithStatus(url) {
        return await fetch(SERVER_URL + url, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${this.getToken()}`,
                "Content-Type": "application/json",
            },
        }).then(resp => {
            if (resp.status === 204) {
                return true;
            }
        });
    }
}
