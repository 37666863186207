// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-admin {
  display: flex;
  flex-direction: column;
  padding: 0 30px 26px 30px;
  width: 100%;
}
.card-admin-filters {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.card-admin-filters .filter__inner {
  margin: 0;
  margin-right: 20px;
}
.card-admin-container {
  margin: 15px 0px 0px;
}

.main__controlButtons {
  margin-top: 28px;
}

@media (max-width: 768px) {
  .card-admin {
    padding: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CardAdmin/CardAdmin.scss","webpack://./src/pages/CardAdmin/CardAdmin.css"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;ACCF;ADAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;ACEJ;ADDI;EACE,SAAA;EACA,kBAAA;ACGN;ADAE;EACE,oBAAA;ACEJ;;ADGE;EACE,gBAAA;ACAJ;;ADWA;EACE;IACE,YAAA;ECRF;AACF","sourcesContent":[".card-admin {\n  display: flex;\n  flex-direction: column;\n  padding: 0 30px 26px 30px;\n  width: 100%;\n  &-filters {\n    display: flex;\n    align-items: center;\n    margin-bottom: 16px;\n    .filter__inner {\n      margin: 0;\n      margin-right: 20px;\n    }\n  }\n  &-container {\n    margin: 15px 0px 0px;\n  }\n}\n\n.main {\n  &__controlButtons {\n    margin-top: 28px;\n    //display: flex;\n   \n    // button {\n    //   &:nth-child(2) {\n    //     margin-left: 20px;\n    //   }\n    // }\n  }\n}\n\n@media (max-width: 768px) {\n  .card-admin {\n    padding: 0px;\n  }\n}\n",".card-admin {\n  display: flex;\n  flex-direction: column;\n  padding: 0 30px 26px 30px;\n  width: 100%;\n}\n.card-admin-filters {\n  display: flex;\n  align-items: center;\n  margin-bottom: 16px;\n}\n.card-admin-filters .filter__inner {\n  margin: 0;\n  margin-right: 20px;\n}\n.card-admin-container {\n  margin: 15px 0px 0px;\n}\n\n.main__controlButtons {\n  margin-top: 28px;\n}\n\n@media (max-width: 768px) {\n  .card-admin {\n    padding: 0px;\n  }\n}/*# sourceMappingURL=CardAdmin.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
