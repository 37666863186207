import { useState } from "react";

import ErrorBox from "../../UI/ErrorBox/ErrorBox";

import "./Textarea.css";

export default function TextArea({
  type = "text",
  value = "",
  placeholder = "Введите текст...",
  id,
  name,
  form,
  error = true,
  label = "",
  border = true,
  disabled = false,
  className,
  inputChange,
  setTextAreaValue,
  title = "",
  mode = "default",
}) {
  const [valueArea, setvVlueArea] = useState("");

  const handleChange = (event) => {
    setvVlueArea(event.target.value);
    setTextAreaValue(event.target.value);
    event.target.current.style.height = `${event.target.scrollHeight}px`;
  };

  return (
    <>
      {mode === "default" && (
        <div className="textarea">
          {label && (
            <label className="textarea_label">
              <p className="textarea_label-text">{title}</p>
            </label>
          )}
          <textarea
            className="textarea_input"
            rows="10"
            cols="45"
            name="text"
            value={valueArea}
            onChange={handleChange}
            placeholder={placeholder}
          />
        </div>
      )}
      {mode === "event" && (
        <div className={`${"textarea"} `}>
          {title !== undefined && (
            <label className="textarea_label">
              {
                <p
                  className={`${"textarea_label-text"}
          ${mode === "event" ? "textarea_label-event" : ""}
          `}
                >
                  {title}
                </p>
              }
            </label>
          )}
          <textarea
            className={`${"textarea_input"} ${className}`}
            rows="10"
            cols="45"
            name={name}
            value={form ? (value === "" ? form.values[name] : value) : value}
            onChange={form ? form.handleChange : inputChange ? (e) => inputChange(e.target.value) : null}
            placeholder={placeholder}
            id={id}
          />
          <ErrorBox error={error} form={form} name={name} />
        </div>
      )}
    </>
  );
}
