// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/other/check.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-group__option {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  height: 48px;
  cursor: pointer;
}
.checkbox-group__input {
  display: flex;
  align-items: center;
}
.checkbox-group__input input:checked {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 116%;
  background-position: center;
}
.checkbox-group__input input {
  appearance: none;
  width: auto;
  margin-right: 21px;
  box-sizing: content-box;
  border: 1px solid #2c2c59;
  border-radius: 3px;
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/UI/Checkbox/CheckboxGroup/CheckboxGroup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mDAAkD;EAClD,qBAAqB;EACrB,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".checkbox-group__option {\n  display: flex;\n  align-items: center;\n  padding-left: 25px;\n  padding-right: 25px;\n  height: 48px;\n  cursor: pointer;\n}\n.checkbox-group__input {\n  display: flex;\n  align-items: center;\n}\n.checkbox-group__input input:checked {\n  background: url(\"../../../images/other/check.png\");\n  background-size: 116%;\n  background-position: center;\n}\n.checkbox-group__input input {\n  appearance: none;\n  width: auto;\n  margin-right: 21px;\n  box-sizing: content-box;\n  border: 1px solid #2c2c59;\n  border-radius: 3px;\n  background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
