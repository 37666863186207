// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.answer__option {
  display: flex;
  padding: 5px;
  min-height: 40px;
  cursor: move;
}
.answer__option > div {
  margin-bottom: 0px;
}
.answer__option img {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateEvent/Answer/Answer.scss","webpack://./src/components/CreateEvent/Answer/Answer.css"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;ACCJ;ADCE;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;ACCJ;ADAI;EACI,kBAAA;ACER;ADCI;EACI,eAAA;ACCR","sourcesContent":[".answer {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n  &__option {\n    display: flex;\n    padding: 5px;\n    min-height: 40px;\n    cursor: move;\n    & > div {\n        margin-bottom: 0px;\n    }\n\n    img {\n        cursor: pointer;\n    }\n    \n  }\n}\n",".answer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.answer__option {\n  display: flex;\n  padding: 5px;\n  min-height: 40px;\n  cursor: move;\n}\n.answer__option > div {\n  margin-bottom: 0px;\n}\n.answer__option img {\n  cursor: pointer;\n}/*# sourceMappingURL=Answer.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
