import { id } from "date-fns/locale";
import { createContext, useReducer } from "react";

export const REQUEST_URL = {
    login: "/api/auth/token/login/",
    logOut: "/api/auth/token/logout/",
    createUser: "/api/auth/users/",
    deleteMultipleUsers: "/api/auth/users/",
    getUserCount: "/api/auth/users/count/",
    updateMultipleCards: "/api/cards/",
    updateMultipleUsers: "/api/auth/users/",
    getFaculties: "/api/facultys/",
    getCurrentUser: "/api/auth/users/me/",
    updateCurrentUser: "/api/auth/users/me/",
    currentUserChangeFaculty: "/api/auth/users/me/change-faculty/",
    getUsersList: "/api/auth/users/",
    getStatistic: "/api/statistic/",
    userChangeFaculty: "/api/auth/users/me/change-faculty/",
    useEducation: "/api/auth/users/me/change-education/",
    useWork: "/api/auth/users/me/change-work/",
    useMSU: "/api/auth/users/me/change-msumembership/",
    useInternship: "/api/auth/users/me/change-internship/",
    useCompetency: "/api/competencies/",
    useTypeOrg: "/api/typeorg/",
    useFormActivity: "/api/formactivity/",
    userMaterialSupport: "/api/auth/users/me/material-support/",
    userApplicationForm: "/api/auth/users/me/application-form/",
    passwordReset: "/api/auth/users/reset_password/",
    passwordResetConfirm: "/api/auth/users/reset_password_confirm/",
    changePassword: "/api/auth/users/set_password/",
    paymentsUsers: "/api/auth/users/payments/",
    resetCurrentUser: "/api/auth/users/me/reset/",
    zipImageUsers: "/api/auth/users/images/",
    getApplicationFormForAdmin: "/api/auth/users/application-form/",
    activateProfile: "/api/auth/users/activation/",
    searchUser: "/api/auth/users/search/",
    getProfkomStatistics: "/api/statistic/",
    updateRzdStatus: "/api/auth/users/rzdstatus/",
    getEventsList: "/api/event/",
    social: "/api/social/",
    getSocialProgramsList: "/api/social/",
    getSponsorsList: "/api/sponsor/",
    setUserAnswer: "/api/answer/",
    usersRequests: "/api/requestsuser/",
    getUsersAnswers: "/api/event/answers/",
    getRequestInfo: "/api/answer/detailanswer/",
    changeAnswerStatus: "/api/answer/status/",
    deleteBadImage: "/api/auth/users/bad_image/",
    getPeopleList: "/api/event/peoplelist/",
    getUser: id => {
        return `/api/auth/users/${id}/`;
    },
    answerId: (id) => {
        return `/api/answer/${id}/`;
    },
    editEvent: (slug) => {
        return `/api/event/${slug}/`;
    },
    editSocial: (slug) => {
        return `/api/social/${slug}/`;
    },
    getEmployeeMail: mail => {
        return `/api/auth/users/?email=${mail}`;
    },


    editEducation: id => {
        return `/api/auth/users/me/edit-education/?id=${id}`;
    },

    editInternship: id => {
        return `/api/auth/users/me/edit-internship/?id=${id}`;
    },

    editWork: id => {
        return `/api/auth/users/me/edit-work/?id=${id}`;
    },

    editMSU: id => {
        return `/api/auth/users/me/edit-msumembership/?id=${id}`;
    },
    
    excelFiles: type => {
        return `/api/auth/users/${type}/`;
    },
    getFacultyContacts: title => {
        return `/api/facultys/${title}/contacts/`;
    },
    updateUser: id => {
        return `/api/auth/users/${id}/`;
    },
    detailEvent: id => {
        return `/api/event/${id}/`;
    },
    detailSocial: id => {
        return `/api/social/${id}/`;
    },
    deleteUser: id => {
        return `/api/auth/users/${id}/`;
    },
    getDownloadStatistics: type => {
        return `/api/statistic/${type}/`;
    }, //excel, csv
};

export const loginContext = createContext();
export const userContext = createContext({});

export const routesCommon = ["/", "/password_reset", "/password_recovery", "/registration", "/activate-profile"];

export const routesList = [
    "/",
    "/password_reset",
    "/password_recovery",
    "/registration",
    "/profile",
    "/documents",
    "/card",
    "/card_admin",
    "/new_users",
    "/requests",
    "/members",
    "/excluded",
    "/table",
    "/statistics",
    "/bookkeeping",
    "/rzd_bonus",
    "/rzd_bonus_profile",
    "/events",
    "/peoples_event",
    "/peoples_social",
    "/peoples_responses",
    "/sponsors",
    "/social_programs_admin",
    "/social_programs_student",
    "/statistics_event",
    "/documents_program",
    "/appeals",
    "/about_event",
    "/about_social_program",
    "/create_event",
];

export const routerPath = {
    main_page: "/",
    password_reset: "/password_reset",
    password_recovery: "/password-recovery/",
    registration: "/registration",
    error: "*",
    profile: "/profile",
    activateProfile: "/activate-profile/*",
    documents: "/documents",
    card: "/card",
    card_admin: "/card_admin",
    table: "/table",
    statistics: "/statistics",
    bookkeeping: "/bookkeeping",
    rzd_bonus: "/rzd_bonus",
    rzd_bonus_profile: "/rzd_bonus_profile",
    events: "/events",
    event: "/events/:id",
    peoples_event: "/peoples_event/:id",
    peoples_social: "/peoples_social/:id",
    peoples_responses: "/peoples_responses/:id",
    sponsors: "/sponsors",
    social_programs_admin: "/social_programs_admin",
    social_program_admin: "/social_programs_admin/:id",
    social_programs_student: "/social_programs_student",
    statistics_event: "/statistics_event",
    documents_program: "/documents_program",
    appeals: "/appeals",
    about_event: "/events/about_event/:event",
    about_social_program: "/about_social_program/:social",
    create_event: "/create_event",
    create_social: "/create_social",
    editEventPage: "/edit-event-page/:slug",
    editSocialPage: "/edit-social-page/:slug",
};

export const routerPathSuMenu = [
    { title: "Создать мероприятие", path: routerPath.create_event },
    { title: "Список мероприятий", path: routerPath.events },
    { title: "Статистика мероприятий", path: routerPath.statistics_event },
];

export const routerPathTables = [
    { title: "Пользователи", path: routerPath.table },
    { title: "Статистика", path: routerPath.statistics },
    // { title: "Карта Zachёt", path: routerPath.card_admin },
    // { title: "Бухгалтерия", path: routerPath.bookkeeping },
    // { title: "РЖД", path: routerPath.rzd_bonus },
];

export const routerPathSocials = [
    { title: "Создать соц. программу", path: routerPath.create_social },
    { title: "Список соц. программ", path: routerPath.social_programs_admin },
];

export const validateParams = {
    academic_level: "Уровень обучения",
    date_of_birth: "Дата рождения",
    email: "Email",
    start_date: "Дата начала обучения",
    end_date: "Дата окончания обучения",
    faculty: "Регион",
    first_name: "Имя",
    first_name_translated: "Транслит имени",
    grade_level: "Текущий Курс",
    image: "Фото",
    last_name: "Фамилия",
    last_name_translated: "Транслит фамилии",
    // middle_name: "Отчество",
    // middle_name_translated: "Транслит отчества",
    phone_number: "Номер телефона",
    sex: "Пол",
    type_of_learning: "Тип обучения",
};

//config pagination
export const PAGINATION_COUNT = 10;
export const PAGINATIONBAR_COUNT = 7;
export const MAX_CARDS = 8;
export const MAX_CHARS = 70;