import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import InputTranslate from "../../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import { ImageLoader } from "../../../interface/ImageLoader";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../../CropPhoto/CropPhoto";
import openNotification from "../MessagePopup";
import Button from "../../../UI/Button/Button";
import Select from "../../../UI/Select/Select";
import Radio from "../../../UI/Radio/Radio";
import Input from "../../../UI/Input/Input";
import DocumentsStudent from "../../DocumentsStudent/DocumentsStudent";
import { showRegistrationErrors, getIncludesValues } from "../../../interface/information-module";
import * as regExpList from "../../../interface/RegExpDictionary";
import CustomValidationUser from "../../../interface/Validation";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";
import DownloadBoxWrapper from "../../DownloadBox/DownloadBoxWrapper";
import FileInput from "../../FileInput/FileInput";
import FileInputList from "../../FileInput/FileInputList";
import { academic_level, COURCES_LIST } from "../../../assets/academic_level";
import { academicLevelSlice } from "../../../assets/academic_level_slice";
import { type_of_learning } from "../../../assets/type_of_learning";
import { useNavigate, useLocation } from "react-router-dom";
import close_window from "../../../images/other/close_window.png";

export default function EditEducationModal(props) {
    const { modalStatus, setModalStatus } = props;
    const info = props.info;
    console.log(info.start_date)

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [croppedData, setCroppedData] = useState("");
    const [isError, setIsError] = useState(false); // стейт для ошибок загружаемых файлов
    const [error, setError] = useState([]); // ошибки загрузки
    const [isVisibleProgressBar, setisVisibleProgressBar] = useState(false);
    const [progressInfos, setProgressInfos] = useState(23);
    const [croppedFile, setCroppedFile] = useState();
    const [inputImage, setInputImage] = useState();
    const inputRef = useRef(null);
    const [filesNameList, setFilesNameList] = useState(["Диплом"]);
    const [canCrop, setCanCrop] = useState(false);
    const [fileList, setFileList] = useState([]);
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ])
    const cropperRef = useRef(null);
    const studentIdRef = useRef(null);
    const courceRef = useRef(null);
    const type_of_learning_ref = useRef(null);
    const navigate = useNavigate();
    const date = new Date().getFullYear();
    const customDateOfBirthValidationDate = (value) => {
        const newValue = value.split("-").reverse().join(".");
        if (newValue !== undefined && String(newValue).slice(9, 10) !== "_") {
            const date = new Date(String(value).split(".").reverse().join("."));
            return newValue;
            // return (
            //     `${Functions.getMonthWithPrefix(date.getDate())}.${Functions.getMonthWithPrefix(
            //         date.getMonth() + 1
            //     )}.${date.getFullYear()}` === String(newValue)
            // );
        }
        return false;
    };
    const [tillNow, setTillNow] = useState(info.end_date == "9999-12-31"? true: false);

    const formikChangeProfile = useFormik({
        initialValues: {
            start_date: customDateOfBirthValidationDate(info.start_date),
            end_date: info.end_date == "9999-12-31"? 'по н.в.': customDateOfBirthValidationDate(info.end_date),
            institution_name: info.institution_name,
            specialization: info.specialization,
        },
        validationSchema: Yup.object({
            // start_date: Yup.string().required("Введите дату начала обучения"),
            // end_date: Yup.string().required("Введите дату окончания обучения"),
            start_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату начала")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-valid-date', 'Дата начала не может быть в будущем', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'

                const today = new Date();
                if (!value) return this.createError({ message: 'Введите правильную дату' });

                const [day, month, year] = value.split('.').map(Number);
                const selectedDate = new Date(year, month - 1, day);

                return selectedDate <= today || this.createError({ message: 'Дата начала не может быть в будущем' });
            }),
        end_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату окончания")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-greater', 'Дата окончания должна быть позже даты начала', function(value) {
                const { start_date } = this.parent;

                if (!start_date || value === 'по н.в.') {
                    return true; // Skip validation if start_date is incomplete or if end_date is 'по н.в.'
                }

                const [startDay, startMonth, startYear] = start_date.split('.').map(Number);
                const startDate = new Date(startYear, startMonth - 1, startDay);

                if (!value) return this.createError({ message: 'Введите правильную дату' });
                
                const [endDay, endMonth, endYear] = value.split('.').map(Number);
                const endDate = new Date(endYear, endMonth - 1, endDay);

                return endDate > startDate || this.createError({ message: 'Дата окончания должна быть позже даты начала' });
            }),
            // .test('is-valid-date', 'Дата окончания не может быть в будущем', function(value) {
            //     if (value === 'по н.в.') return true; // Allow 'по н.в.'

            //     const today = new Date();

            //     if (!value) {
            //         return this.createError({ message: 'Введите правильную дату' });
            //     }

            //     const [day, month, year] = value.split('.').map(Number);
            //     const selectedDate = new Date(year, month - 1, day);

            //     return selectedDate <= today || this.createError({ message: 'Дата окончания не может быть в будущем' });
            // }),
            institution_name: Yup.string().required("Введите название образовательного учреждения"),
            specialization: Yup.string().required("Введите название специализации"),
        }),
        onSubmit: async values => {
            console.log("TEST");
    
            // Преобразование дат в нужный формат (если требуется)
            values.start_date = values.start_date.split(".").reverse().join("-");
            values.end_date = values.end_date === 'по н.в.' ? '9999-12-31' : values.end_date.split(".").reverse().join("-");
            
            console.log(values.start_date);
            
           
            
            try {
                const response = await HTTP.Patch(`${REQUEST_URL.editEducation(info.id)}`, values);
                
                if (response.status === 200) {
                    console.log("success");
                    openNotification({
                        title: "Изменение профиля",
                        text: "Данные успешно обновлены",
                        type: "success",
                    });
                    setModalStatus("none");
                    navigate(0);
                } else {
                    throw new Error("Unexpected response status");
                }
            } catch (error) {
                console.log("error", error);
                openNotification({
                    title: "Изменение профиля",
                    text: "Что-то пошло не так",
                    type: "error",
                });
                setRegistrationErrors(error.response ? error.response.data : error.message);
            }
        },
    });
    //     initialValues: {
    //         start_date: customDateOfBirthValidationDate(info.start_date),
    //         end_date: customDateOfBirthValidationDate(info.end_date),
    //         institution_name: info.institution_name,
    //         specialization: info.specialization,
    //     },
    //     validationSchema: Yup.object({
    //         start_date: Yup.string().required("Введите дату начала обучения"),
    //         end_date: Yup.string().required("Введите дату окончания обучения"),
    //         institution_name: Yup.string().required("Введите название образовательного обучения"),
    //         specialization: Yup.string().required("Введите название специализации"),
    //     }),
    //     onSubmit: async values => {
    //         console.log("TEST")
    //         console.log(values.start_date)
    //         values.start_date = values.start_date.split(".").reverse().join("-");
    //         values.end_date = values.end_date.split(".").reverse().join("-");
    //         console.log(values.start_date)
    //         await setSubmitInfo(values);
    //         const responce = await HTTP.Post(REQUEST_URL.useEducation, values);
    //         console.log(responce.text)
    //         if (responce.id) {
    //             console.log("success")
    //             openNotification({
    //                 title: "Добавление юзера",
    //                 text: "Задача успешно выполнена",
    //                 type: "success",
    //             });
    //             setModalStatus("none");
    //         } else {
    //             console.log("error")
    //             openNotification({
    //                 title: "Добавление юзера",
    //                 text: "Что-то пошло не так",
    //                 type: "error",
    //             });
    //             setRegistrationErrors(responce);
    //         }
    //     },
    // });

    async function setSubmitInfo(values) {
        await fetchUpdateProfile(values);
    }

    async function fetchUpdateProfile(values) {
        let formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                // const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], item[1]);
            }
            else {
                formData.append(`${item[0]}`, item[1]);
            }
        }
        return await HTTP.UpdateUser("PATCH", false, REQUEST_URL.updateCurrentUser, formData);
    }

    const handleTillNowChange = () => {
        setTillNow(!tillNow);
        if (!tillNow) {
            formikChangeProfile.setFieldValue('end_date', 'по н.в.');
        } else {
            formikChangeProfile.setFieldValue('end_date', '');
        }
    };

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div
                    className="modal-close"
                    onClick={() => {
                        setModalStatus("none");
                    }}
                >
                    <img src={close_window} alt="Close" />
                </div>

                <form
                    className={
                        modalStatus !== "material_support" && modalStatus !== "change_faculty"
                            ? "modalForms-mainEdit change-modal"
                            : "modalForms-mainEdit"
                    }
                >
                    <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                        <div className="modalForms-header-image">
                        
                        </div>
                        {/* <div className="modalForms-header-info">
                            <div className="modalForms-header-title">Подтвеждающий документ</div>
                        </div> */}
                    </div>
                    <Input
                        text="Наименование образовательной организации"
                        required={true}
                        id="institution_name"
                        disabled = {false}
                        {...formikChangeProfile.getFieldProps("institution_name")}
                        formError={formikChangeProfile}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Дата начала"
                        required={true}
                        id="start_date"
                        mask={"99.99.9999"}
                        {...formikChangeProfile.getFieldProps("start_date")}
                        formError={formikChangeProfile}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Дата окончания"
                        required={true}
                        id="end_date"
                        mask={tillNow? "" : "99.99.9999"}
                        {...formikChangeProfile.getFieldProps("end_date")}
                        formError={formikChangeProfile}
                    />
                    <div className="user__participations__section__organisationType">
                    <label>
                        <input
                            type="checkbox"
                            checked={tillNow}
                            onChange={handleTillNowChange}
                        />
                        По настоящее время
                    </label>
                    </div>
                    <Input
                        type="text"
                        text="Специальность"
                        required={true}
                        id="specialization"
                        {...formikChangeProfile.getFieldProps("specialization")}
                        formError={formikChangeProfile}
                    />
                    
                    <div>
                        
                    </div>
                    <button type="button" className="button button_blue" color="blue"
                    onClick={() => {
                        formikChangeProfile.handleSubmit()
                        setModalStatus("none");
                    }}
                    >Сохранить</button>
                    <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                </form>
            </div>
            {/* 
        <Modals
          page="registration"
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
        /> */}
        </>
    );
}

// AddProfileModal
