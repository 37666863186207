import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import InputTranslate from "../../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import { ImageLoader } from "../../../interface/ImageLoader";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../../CropPhoto/CropPhoto";
import openNotification from "../MessagePopup";
import Button from "../../../UI/Button/Button";
import Select from "../../../UI/Select/Select";
import Radio from "../../../UI/Radio/Radio";
import Input from "../../../UI/Input/Input";
import DocumentsStudent from "../../DocumentsStudent/DocumentsStudent";
import { showRegistrationErrors, getIncludesValues } from "../../../interface/information-module";
import * as regExpList from "../../../interface/RegExpDictionary";
import CustomValidationUser from "../../../interface/Validation";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";
import DownloadBoxWrapper from "../../DownloadBox/DownloadBoxWrapper";
import FileInput from "../../FileInput/FileInput";
import FileInputList from "../../FileInput/FileInputList";
import { academic_level, COURCES_LIST } from "../../../assets/academic_level";
import { academicLevelSlice } from "../../../assets/academic_level_slice";
import { type_of_learning } from "../../../assets/type_of_learning";

import close_window from "../../../images/other/close_window.png";

export default function AddInternshipModal(props) {
    const { modalStatus, setModalStatus } = props;
    const addItem = props.addItem;

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [croppedData, setCroppedData] = useState("");
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false); // стейт для ошибок загружаемых файлов
    const [error, setError] = useState([]); // ошибки загрузки
    const [isVisibleProgressBar, setisVisibleProgressBar] = useState(false);
    const [progressInfos, setProgressInfos] = useState(23);
    const [croppedFile, setCroppedFile] = useState();
    const [inputImage, setInputImage] = useState();
    const inputRef = useRef(null);
    const [file, setFile] = useState();
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [canCrop, setCanCrop] = useState(false);
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ])
    const [typeorg, setTypeOrg] = useState([
        { id: "", title: "Выберите тип организации" },
    ]);
    const [formActivity, setFormActivity] = useState([
        { id: "", title: "Выберите форму деятельности" },
    ]);

    const [selectedTypeorg, setTypeOrgSelected] = useState();
    const [selectedFormActivity, setFormActivitySelected] = useState();
    const [tillNow, setTillNow] = useState(false);

    const formikAddUser = useFormik({
        initialValues: {
            start_date: "",
            end_date: "",
            organization_name: "",
            position: "",
            type_org: "",
            name_activity: "",
            has_org_id: false,
            file: null,
            faculty: "",
        },
        validationSchema: Yup.object({
            // start_date: Yup.string().required("Введите дату начала обучения"),
            // end_date: Yup.string().required("Введите дату окончания обучения"),
            start_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату начала")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-valid-date', 'Дата начала не может быть в будущем', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'

                const today = new Date();
                if (!value) return this.createError({ message: 'Введите правильную дату' });

                const [day, month, year] = value.split('.').map(Number);
                const selectedDate = new Date(year, month - 1, day);

                return selectedDate <= today || this.createError({ message: 'Дата начала не может быть в будущем' });
            }),
        end_date: Yup.string()
            .nullable() // Allow undefined values
            .required("Введите дату окончания")
            .test('is-valid-format', 'Введите правильную дату"', function(value) {
                if (value === 'по н.в.') return true; // Allow 'по н.в.'
                return /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(value);
            })
            .test('is-greater', 'Дата окончания должна быть позже даты начала', function(value) {
                const { start_date } = this.parent;

                if (!start_date || value === 'по н.в.') {
                    return true; // Skip validation if start_date is incomplete or if end_date is 'по н.в.'
                }

                const [startDay, startMonth, startYear] = start_date.split('.').map(Number);
                const startDate = new Date(startYear, startMonth - 1, startDay);

                if (!value) return this.createError({ message: 'Введите правильную дату' });
                
                const [endDay, endMonth, endYear] = value.split('.').map(Number);
                const endDate = new Date(endYear, endMonth - 1, endDay);

                return endDate > startDate || this.createError({ message: 'Дата окончания должна быть позже даты начала' });
            }),
            // .test('is-valid-date', 'Дата окончания не может быть в будущем', function(value) {
            //     if (value === 'по н.в.') return true; // Allow 'по н.в.'

            //     const today = new Date();

            //     if (!value) {
            //         return this.createError({ message: 'Введите правильную дату' });
            //     }

            //     const [day, month, year] = value.split('.').map(Number);
            //     const selectedDate = new Date(year, month - 1, day);

            //     return selectedDate <= today || this.createError({ message: 'Дата окончания не может быть в будущем' });
            // }),
            organization_name: Yup.string().required("Введите название организации"),
            position: Yup.string().required("Введите название должности"),
            type_org: Yup.string().required("Выберите тип организации"),
            name_activity: Yup.string().required("Выберите форму деятельности"),
            file: Yup.mixed().required("Загрузите файл"),
        }),
        onSubmit: async values => {
            console.log(values)
            values.name_activity = selectedFormActivity;
            values.type_org = selectedTypeorg;
            values.file = file;
            values.start_date = values.start_date.split(".").reverse().join("-");
            values.end_date = values.end_date === 'по н.в.' ? '9999-12-31' : values.end_date.split(".").reverse().join("-");
            await setSubmitInfo(values);
            setModalStatus("none");

            // const responce = await HTTP.Post(REQUEST_URL.useEducation, values);
            // console.log(responce.text)
        },
    });

    useEffect(() => {
        // Функция для выполнения запроса к API
        const fetchTypeOrg = async () => {
            try {
                const response = await HTTP.Get(REQUEST_URL.useTypeOrg);
                console.log(response)
                setTypeOrg((prevTypeOrg) => [...prevTypeOrg, ...response]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        // Вызов функции
        fetchTypeOrg();
    }, []);

    useEffect(() => {
        // Функция для выполнения запроса к API
        const fetchFormActivity = async () => {
            try {
                const response = await HTTP.Get(REQUEST_URL.useFormActivity);
                console.log(response)
                setFormActivity((prevFormActivity) => [...prevFormActivity, ...response]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        // Вызов функции
        fetchFormActivity();
    }, []);

    async function setSubmitInfo(values) {
        let formData = new FormData();
        for (let item of Object.entries(values)) {
            console.log(values)
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            }
            else if (item[0] === "file") {
                formData.append(item[0], item[1]);
            }
            else {
                formData.append(item[0], item[1]);
            }
        }
        try {
            const response = await HTTP.PostProgress(REQUEST_URL.useInternship, formData, changeProgress);
            console.log('Response:', response);
            if (response.id) {
                addItem({
                    id: response.id,
                    status: response.status,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    organization_name: values.organization_name,
                    position: values.position,
                    type_org: values.type_org,
                    name_activity: values.name_activity,
                    has_org_id: values.has_org_id,
                    file: response.file,
                });

                openNotification({
                    title: "Добавление юзера",
                    text: "Задача успешно выполнена",
                    type: "success",
                });
                // setModalStatus("none");
            } else {
                openNotification({
                    title: "Добавление юзера",
                    text: "Что-то пошло не так",
                    type: "error",
                });
                setRegistrationErrors(response);
            }
            // Handle success
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    }

    const changeProgress = (progress) => {
        setProgress(progress);
    };

    useEffect(() => {
        if (formikAddUser.values.faculty !== "") {
            setDisableFields(false);
        } else {
            setDisableFields(true);
        }
    }, [formikAddUser.values.faculty]);

    useEffect(() => {
        if (formikAddUser.values.academic_level !== "") {
            setDisableGradeLevel(false);
        } else {
            setDisableGradeLevel(true);
        }
    }, [formikAddUser.values.academic_level]);

    const handleNameActivityChange = (e) => {
        setFormActivitySelected(e.target.value);
        console.log(e.target.value);
        console.log(selectedFormActivity);
        formikAddUser.values.name_activity = e.target.value;
    };

    const handleTypeOrgChange = (e) => {
        setTypeOrgSelected(e.target.value);
        formikAddUser.values.type_org = e.target.value;
    };

    const handleTillNowChange = () => {
        setTillNow(!tillNow);
        if (!tillNow) {
            formikAddUser.setFieldValue('end_date', 'по н.в.');
        } else {
            formikAddUser.setFieldValue('end_date', '');
        }
    };

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div
                    className="modal-close"
                    onClick={() => {
                        setModalStatus("none");
                    }}
                >
                    <img src={close_window} alt="Close" />
                </div>

                <form
                    className={
                        modalStatus !== "material_support" && modalStatus !== "change_faculty"
                            ? "modalForms-mainEdit change-modal"
                            : "modalForms-mainEdit"
                    }
                >
                    <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                        <div className="modalForms-header-image">

                        </div>
                        {/* <div className="modalForms-header-info">
                            <div className="modalForms-header-title">Подтвеждающий документ</div>
                        </div> */}
                    </div>
                    <Select
                        required={true}
                        id="name_activity"
                        name="name_activity"
                        // {...formikAddUser.getFieldProps("name_activity")}
                        text="Форма деятельности"
                        defaultValue={"Выберите форму деятельности"}
                        onChange={handleNameActivityChange}
                        list={formActivity}
                        disable={false}
                        mode={"default2"}
                        formError={formikAddUser}
                    />

                    <Select
                        list={typeorg}
                        required={true}
                        text="Тип организации"
                        defaultValue={"Выберите тип организации"}
                        id="type_org"
                        name="type_org"
                        disable={false}
                        mode={"default2"}
                        onChange={handleTypeOrgChange}
                        // {...formikAddUser.getFieldProps("type_org")}
                        formError={formikAddUser}
                    />

                    <div>
                        Прикрепите подтверждающий документ<br />(Характеристика или письмо с места практики или приказ о приёме на работу)
                    </div>
                    <Input
                        required={true}
                        mode="fileUpload"
                        id="file"
                        name="file"
                        accept="application/pdf"
                        {...formikAddUser.getFieldProps("file")}
                        formError={formikAddUser}
                        value={formikAddUser.values.file}
                        takeFile={(e) => {
                            setFile(e.target.files[0])
                            formikAddUser.values.file = e.target.files[0];
                        }}
                    // takeFile={e => setFile(e.target.files[0])}
                    />

                    {isUploading && (
                        <div>
                            <div style={{ width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                                <div style={{ width: `${progress}%`, height: '30px', backgroundColor: '#4caf50', borderRadius: '5px' }} />
                            </div>
                            <p>Загрузка файла: {progress.toFixed(2)}%</p>
                        </div>
                    )}

                    <Input
                        text="Наименование организации"
                        required={true}
                        id="organization_name"
                        name="organization_name"
                        {...formikAddUser.getFieldProps("organization_name")}
                        formError={formikAddUser}
                        value={formikAddUser.values.organization_name}
                    />
                    <div className="modal-date__wrapper">
                        <MaskedCustomInput
                            type="text"
                            text="Дата начала"
                            required={true}
                            id="start_date"
                            name="start_date"
                            {...formikAddUser.getFieldProps("start_date")}
                            formError={formikAddUser}
                            mask={"99.99.9999"}
                        />
                        <MaskedCustomInput
                            type="text"
                            text="Cрок окончания"
                            required={true}
                            id="end_date"
                            name="end_date"
                            {...formikAddUser.getFieldProps("end_date")}
                            formError={formikAddUser}
                        mask={tillNow ? "" : "99.99.9999"}
                        />
                        <div className="user__participations__section__organisationType">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={tillNow}
                                    onChange={handleTillNowChange}
                                />
                                По настоящее время
                            </label>
                        </div>
                    </div>
                    <Input
                        type="text"
                        text="Должность"
                        required={true}
                        id="position"
                        name="position"
                        {...formikAddUser.getFieldProps("position")}
                        formError={formikAddUser}
                    />
                    <div className="user__participations__section__organisationType">
                        <label> <input type="checkbox"  {...formikAddUser.getFieldProps("has_org_id")} /> Руководящая должность (начальник отдела и выше)</label>
                    </div>
                    {/* <Button text="ЗАРЕГИСТРИРОВАТЬ" type="button" color="blue" onClick={async () => {
                        console.log("TEST");
                    }} /> */}
                    <button type="button" className="button button_blue" color="blue" onClick={formikAddUser.handleSubmit}>Добавить значение</button>
                    <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                </form>
            </div>
            {/* 
        <Modals
          page="registration"
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
        /> */}
        </>
    );
}

// AddProfileModal
